import { Text, useBreakpointValue } from '@chakra-ui/react'
import memoize from 'memoizee'
import { Cell } from 'react-table'

import { ModemWithContext } from '@beaded/models'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { DataTable } from 'components/Table'

import { deleteModem } from 'lib/mutations/delete/deleteModem'

import type { KeysOf } from 'types/common'

type ModemCell = Cell & {
  row: {
    original: ModemWithContext
  }
}

interface ModemsTableProps {
  data: any
  globalFilter: any
  projection: ModemTableProjection
  download: any
  sortBy?: Array<any>
}

export const ModemsTable = ({
  data = [],
  globalFilter,
  projection = 'default',
  download,
  sortBy = [],
}: ModemsTableProps) => {
  const zoom = useBreakpointValue({
    base: 0.9,
    md  : 1,
  })

  return (
    <>
      {data.length ? (
        <DataTable
          data={data}
          columns={projections[projection]}
          initialGlobalFilter={globalFilter}
          hiddenColumns={hiddenColumns}
          sortBy={sortBy}
          download={download}
          sx={{
            zoom,
            mozTransform: `scale(${zoom})`,
          }}
        />
      ) : (
        <Text>no data found</Text>
      )}
    </>
  )
}

const hiddenColumns = ['id']

const columns = {
  imei: {
    Header  : 'IMEI',
    accessor: 'imei',
    Cell    : ({ value, row }: ModemCell) => {
      if (value) return <InternalLink name={value} href={`/modems/${value}`} />

      return <InternalLink name={value} href={`/modems/${value}`} />
    },
  },

  currentLogger: {
    Header  : 'Current Logger',
    accessor: memoize((originalRow: ModemWithContext) => {
      const currentHistory = originalRow?.history?.at(-1)
      if (!currentHistory) return null
      if (
        currentHistory?.endedAt &&
        new Date(currentHistory.endedAt) < new Date()
      )
        return null
      return currentHistory?.loggerSerial
    }),
    Cell: ({ value, row }: ModemCell) => {
      if (value) {
        const currentHistory = row?.original?.history?.at(-1)
        return (
          <InternalLink
            name={value}
            href={`/loggers/${currentHistory?.loggerId}`}
          />
        )
      }

      return null
    },
  },

  historyEntries: {
    Header  : 'History Entries',
    accessor: memoize(
      (originalRow: any, rowIndex: any) => originalRow?.history?.length,
    ),
  },

  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: ModemCell) => {
      if (!value) return null

      return <NotesModal note={value} name={row.values?.name} />
    },
  },

  actions: actions({
    deleteFn: deleteModem,
    getName : (data) => data.imei,
  }),
}

const projections = {
  default: [
    columns.imei,
    columns.currentLogger,
    columns.historyEntries,
    columns.notes,
    columns.actions,
  ],
}

export type ModemTableProjection = KeysOf<typeof projections>
