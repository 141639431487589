import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.last_logger_transmission_ */
export type LastLoggerTransmissionId = string & {
  __brand: 'LastLoggerTransmissionId'
}

/** Represents the table public.last_logger_transmission_ */
export default interface LastLoggerTransmissionTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    LastLoggerTransmissionId,
    LastLoggerTransmissionId | undefined,
    LastLoggerTransmissionId
  >

  /** Database type: pg_catalog.text */
  loggerSerial: ColumnType<string, string, string>

  /** Database type: pg_catalog.numeric */
  battery: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.jsonb */
  batteryTrend: ColumnType<unknown | null, unknown | null, unknown | null>

  /** Database type: pg_catalog.int4 */
  msgSize: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  errors85: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  errors99: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  errors125: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  numConnectedDevices: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.timestamptz */
  recievedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.text */
  imei: ColumnType<string | null, string | null, string | null>
}

export type LastLoggerTransmission = Selectable<LastLoggerTransmissionTable>

export type LastLoggerTransmissionInitializer =
  Insertable<LastLoggerTransmissionTable>

export type LastLoggerTransmissionMutator =
  Updateable<LastLoggerTransmissionTable>

export const lastLoggerTransmissionId =
  z.string() as unknown as z.Schema<LastLoggerTransmissionId>

export const lastLoggerTransmission = z.object({
  id                 : lastLoggerTransmissionId,
  loggerSerial       : z.string(),
  battery            : z.number().nullable().optional(),
  batteryTrend       : z.unknown().nullable().optional(),
  msgSize            : z.number().nullable().optional(),
  errors85           : z.number().nullable().optional(),
  errors99           : z.number().nullable().optional(),
  errors125          : z.number().nullable().optional(),
  numConnectedDevices: z.number().nullable().optional(),
  recievedAt         : z.coerce.date(),
  imei               : z.string().nullable().optional(),
}) as unknown as z.Schema<LastLoggerTransmission>

export const lastLoggerTransmissionInitializer = z.object({
  id                 : lastLoggerTransmissionId.optional(),
  loggerSerial       : z.string(),
  battery            : z.number().optional().nullable().optional(),
  batteryTrend       : z.unknown().optional().nullable().optional(),
  msgSize            : z.number().optional().nullable().optional(),
  errors85           : z.number().optional().nullable().optional(),
  errors99           : z.number().optional().nullable().optional(),
  errors125          : z.number().optional().nullable().optional(),
  numConnectedDevices: z.number().optional().nullable().optional(),
  recievedAt         : z.coerce.date(),
  imei               : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LastLoggerTransmissionInitializer>

export const lastLoggerTransmissionMutator = z.object({
  id                 : lastLoggerTransmissionId.optional(),
  loggerSerial       : z.string().optional(),
  battery            : z.number().optional().nullable().optional(),
  batteryTrend       : z.unknown().optional().nullable().optional(),
  msgSize            : z.number().optional().nullable().optional(),
  errors85           : z.number().optional().nullable().optional(),
  errors99           : z.number().optional().nullable().optional(),
  errors125          : z.number().optional().nullable().optional(),
  numConnectedDevices: z.number().optional().nullable().optional(),
  recievedAt         : z.coerce.date().optional(),
  imei               : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LastLoggerTransmissionMutator>
