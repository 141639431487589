import { InfoIcon } from '@chakra-ui/icons'
import { IconButton, Center, Tooltip } from '@chakra-ui/react'
import ReactJson from 'react-json-view'

import { ModalContainer } from 'containers/ModalContainer'

interface InfoModalProps {
  data: any
  title?: string
  iconButtonProps?: any
  center?: boolean
  tooltip?: string
}

export const InfoModal = ({
  data,
  title = 'Info',
  iconButtonProps = {},
  center = false,
  tooltip = '',
}: InfoModalProps) => {
  return (
    <ModalContainer
      TriggerButton={({ onClick }) => (
        <>
          <Tooltip label={tooltip}>
            {center ? (
              <Center>
                <IconButton
                  icon={<InfoIcon />}
                  onClick={onClick}
                  {...iconButtonProps}
                />
              </Center>
            ) : (
              <IconButton
                icon={<InfoIcon />}
                onClick={onClick}
                {...iconButtonProps}
              />
            )}
          </Tooltip>
        </>
      )}
      modalProps={{ size: 'xl' }}
      header={title}
    >
      <ReactJson
        src={data}
        indentWidth={2}
        collapsed={1}
        groupArraysAfterLength={25}
      />
    </ModalContainer>
  )
}
