import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'

/** Represents the view public.logger_events_view_ */
export default interface LoggerEventsViewTable {
  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, never, never>

  /** Database type: pg_catalog.text */
  eventType: ColumnType<string, never, never>

  /** Database type: pg_catalog.timestamptz */
  eventDate: ColumnType<Date, never, never>

  /** Database type: pg_catalog.text */
  raw: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.text */
  text: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.int4 */
  cableSerial: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.text */
  onewireId: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.text */
  createdByUser: ColumnType<string, never, never>
}

export type LoggerEventsView = Selectable<LoggerEventsViewTable>

export const loggerEventsView = z.object({
  loggerId     : loggerId,
  eventType    : z.string(),
  eventDate    : z.coerce.date(),
  raw          : z.string().nullable().optional(),
  text         : z.string().nullable().optional(),
  cableSerial  : z.number().nullable().optional(),
  onewireId    : z.string().nullable().optional(),
  createdAt    : z.coerce.date().nullable().optional(),
  createdBy    : z.string().nullable().optional(),
  createdByUser: z.string(),
}) as unknown as z.Schema<LoggerEventsView>
