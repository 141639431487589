import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query'

import { LoggerWithContext } from '@beaded/models'

import { logger } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import type { Obj } from 'types/common'

export const useLoggersList = (
  filter?: Obj,
  queryOpts: UseQueryOptions = {},
  remapData?: (data: any) => any,
  filterData?: (data: any) => any,
) => {
  const { superUser } = useUserContext()

  const options: [QueryKey, QueryFunction, UseQueryOptions] =
    superUser === 'on'
      ? [
        ['loggers', filter, { superUser }],
        ({ queryKey }) => logger.admin.getAll({ params: queryKey[1] }),
        queryOpts,
      ]
      : [
        ['loggers', filter],
        ({ queryKey }) => logger.getAll({ params: queryKey[1] }),
        queryOpts,
      ]

  const { data, ...rest } = useQuery<{ loggers: LoggerWithContext[] }>(
    ...options,
  )

  let loggers = data?.loggers ?? []

  if (remapData) loggers = loggers.map(remapData)
  if (filterData) loggers = loggers.filter(filterData)

  return {
    loggers,
    ...rest,
  }
}
