import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.logger_ */
export type LoggerId = number & { __brand: 'LoggerId' }

/** Represents the table public.logger_ */
export default interface LoggerTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<LoggerId, LoggerId | undefined, LoggerId>

  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  firmwareVersion: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  loggerSerial: ColumnType<string, string, string>

  /** Database type: pg_catalog.int4 */
  inferredIntervalLog: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  inferredIntervalTransmit: ColumnType<
    number | null,
    number | null,
    number | null
  >

  /** Database type: pg_catalog.int4 */
  inferredTxPeriod: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  configuredIntervalLog: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.int4 */
  configuredIntervalTransmit: ColumnType<
    number | null,
    number | null,
    number | null
  >

  /** Database type: pg_catalog.int4 */
  configuredTxPeriod: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  storageCapacity: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  deviceType: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  port1Type: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  port1Function: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  port1Enabled: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  port2Type: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  port2Function: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  port2Enabled: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  port3Type: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  port3Function: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  port3Enabled: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  port4Type: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  port4Function: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  port4Enabled: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  port5Type: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  port5Function: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  port5Enabled: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.text */
  chipset: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, string | null, string | null>
}

export type Logger = Selectable<LoggerTable>

export type LoggerInitializer = Insertable<LoggerTable>

export type LoggerMutator = Updateable<LoggerTable>

export const loggerId = z.number() as unknown as z.Schema<LoggerId>

export const logger = z.object({
  id                        : loggerId,
  createdAt                 : z.coerce.date().nullable().optional(),
  createdBy                 : z.string().nullable().optional(),
  updatedAt                 : z.coerce.date().nullable().optional(),
  updatedBy                 : z.string().nullable().optional(),
  deletedAt                 : z.coerce.date().nullable().optional(),
  deletedBy                 : z.string().nullable().optional(),
  firmwareVersion           : z.string().nullable().optional(),
  loggerSerial              : z.string(),
  inferredIntervalLog       : z.number().nullable().optional(),
  inferredIntervalTransmit  : z.number().nullable().optional(),
  inferredTxPeriod          : z.number().nullable().optional(),
  configuredIntervalLog     : z.number().nullable().optional(),
  configuredIntervalTransmit: z.number().nullable().optional(),
  configuredTxPeriod        : z.number().nullable().optional(),
  storageCapacity           : z.string().nullable().optional(),
  deviceType                : z.string().nullable().optional(),
  port1Type                 : z.string().nullable().optional(),
  port1Function             : z.string().nullable().optional(),
  port1Enabled              : z.boolean().nullable().optional(),
  port2Type                 : z.string().nullable().optional(),
  port2Function             : z.string().nullable().optional(),
  port2Enabled              : z.boolean().nullable().optional(),
  port3Type                 : z.string().nullable().optional(),
  port3Function             : z.string().nullable().optional(),
  port3Enabled              : z.boolean().nullable().optional(),
  port4Type                 : z.string().nullable().optional(),
  port4Function             : z.string().nullable().optional(),
  port4Enabled              : z.boolean().nullable().optional(),
  port5Type                 : z.string().nullable().optional(),
  port5Function             : z.string().nullable().optional(),
  port5Enabled              : z.boolean().nullable().optional(),
  chipset                   : z.string().nullable().optional(),
  notes                     : z.string().nullable().optional(),
}) as unknown as z.Schema<Logger>

export const loggerInitializer = z.object({
  id                        : loggerId.optional(),
  createdAt                 : z.coerce.date().optional().nullable().optional(),
  createdBy                 : z.string().optional().nullable().optional(),
  updatedAt                 : z.coerce.date().optional().nullable().optional(),
  updatedBy                 : z.string().optional().nullable().optional(),
  deletedAt                 : z.coerce.date().optional().nullable().optional(),
  deletedBy                 : z.string().optional().nullable().optional(),
  firmwareVersion           : z.string().optional().nullable().optional(),
  loggerSerial              : z.string(),
  inferredIntervalLog       : z.number().optional().nullable().optional(),
  inferredIntervalTransmit  : z.number().optional().nullable().optional(),
  inferredTxPeriod          : z.number().optional().nullable().optional(),
  configuredIntervalLog     : z.number().optional().nullable().optional(),
  configuredIntervalTransmit: z.number().optional().nullable().optional(),
  configuredTxPeriod        : z.number().optional().nullable().optional(),
  storageCapacity           : z.string().optional().nullable().optional(),
  deviceType                : z.string().optional().nullable().optional(),
  port1Type                 : z.string().optional().nullable().optional(),
  port1Function             : z.string().optional().nullable().optional(),
  port1Enabled              : z.boolean().optional().nullable().optional(),
  port2Type                 : z.string().optional().nullable().optional(),
  port2Function             : z.string().optional().nullable().optional(),
  port2Enabled              : z.boolean().optional().nullable().optional(),
  port3Type                 : z.string().optional().nullable().optional(),
  port3Function             : z.string().optional().nullable().optional(),
  port3Enabled              : z.boolean().optional().nullable().optional(),
  port4Type                 : z.string().optional().nullable().optional(),
  port4Function             : z.string().optional().nullable().optional(),
  port4Enabled              : z.boolean().optional().nullable().optional(),
  port5Type                 : z.string().optional().nullable().optional(),
  port5Function             : z.string().optional().nullable().optional(),
  port5Enabled              : z.boolean().optional().nullable().optional(),
  chipset                   : z.string().optional().nullable().optional(),
  notes                     : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LoggerInitializer>

export const loggerMutator = z.object({
  id                        : loggerId.optional(),
  createdAt                 : z.coerce.date().optional().nullable().optional(),
  createdBy                 : z.string().optional().nullable().optional(),
  updatedAt                 : z.coerce.date().optional().nullable().optional(),
  updatedBy                 : z.string().optional().nullable().optional(),
  deletedAt                 : z.coerce.date().optional().nullable().optional(),
  deletedBy                 : z.string().optional().nullable().optional(),
  firmwareVersion           : z.string().optional().nullable().optional(),
  loggerSerial              : z.string().optional(),
  inferredIntervalLog       : z.number().optional().nullable().optional(),
  inferredIntervalTransmit  : z.number().optional().nullable().optional(),
  inferredTxPeriod          : z.number().optional().nullable().optional(),
  configuredIntervalLog     : z.number().optional().nullable().optional(),
  configuredIntervalTransmit: z.number().optional().nullable().optional(),
  configuredTxPeriod        : z.number().optional().nullable().optional(),
  storageCapacity           : z.string().optional().nullable().optional(),
  deviceType                : z.string().optional().nullable().optional(),
  port1Type                 : z.string().optional().nullable().optional(),
  port1Function             : z.string().optional().nullable().optional(),
  port1Enabled              : z.boolean().optional().nullable().optional(),
  port2Type                 : z.string().optional().nullable().optional(),
  port2Function             : z.string().optional().nullable().optional(),
  port2Enabled              : z.boolean().optional().nullable().optional(),
  port3Type                 : z.string().optional().nullable().optional(),
  port3Function             : z.string().optional().nullable().optional(),
  port3Enabled              : z.boolean().optional().nullable().optional(),
  port4Type                 : z.string().optional().nullable().optional(),
  port4Function             : z.string().optional().nullable().optional(),
  port4Enabled              : z.boolean().optional().nullable().optional(),
  port5Type                 : z.string().optional().nullable().optional(),
  port5Function             : z.string().optional().nullable().optional(),
  port5Enabled              : z.boolean().optional().nullable().optional(),
  chipset                   : z.string().optional().nullable().optional(),
  notes                     : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<LoggerMutator>
