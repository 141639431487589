import { QuestionIcon, SearchIcon } from '@chakra-ui/icons'
import {
  Text,
  HStack,
  Input,
  InputGroup,
  InputRightElement,
  Tooltip,
  IconButton,
  Box,
  Kbd,
  Center,
  Image,
  VStack,
  useColorMode,
} from '@chakra-ui/react'
import { useEffect, useState } from 'react'
import React from 'react'

import darkTextLogo from 'assets/beadedcloud-dark-font.svg'
import lightTextLogo from 'assets/beadedcloud-white-font.svg'

import { CablesCard } from 'components/CablesCard'
import { ErrorCard } from 'components/ErrorCard'
import { Loading } from 'components/Loading'
import { LoggersCard } from 'components/LoggersCard'
import { ModemsCard } from 'components/ModemsCard'
import { OrgsCard } from 'components/OrgsCard'
import { ProjectsCard } from 'components/ProjectsCard'
import { SitesCard } from 'components/SitesCard'

import { Card } from 'containers/Card'

import { useKeyboardShortcut } from 'hooks/useKeyboardShortcut'
import { useRouter } from 'hooks/useRouter'
import { useSearchParams } from 'hooks/useSearchParams'
import { useUserContext } from 'hooks/useUserContext'

import { Users } from 'pages/Users/Users'

import { useSearch } from './useSearch'

export default function SearchWrapper() {
  return <Search />
}

export const Search = () => {
  const { globalFilter } = useSearchParams()
  const { global } = useUserContext()
  const { isLoading, errors, results } = useSearch(globalFilter)
  const { colorMode } = useColorMode()

  return (
    <>
      <Card cardProps={{ width: '60%', opacity: '0.9' }}>
        <Center>
          <VStack width='100%' p={5}>
            <Image
              h='3.5rem'
              src={colorMode === 'light' ? darkTextLogo : lightTextLogo}
            />
            <Box w='100%'>
              <SearchInput />
            </Box>
          </VStack>
        </Center>
      </Card>

      {errors && <ErrorCard errors={errors} />}

      {isLoading && <Loading />}

      {!isLoading &&
        results !== undefined &&
        Object.keys(results).length === 0 && (
        <Card>
          <Center p={6}>
            <Text>No results found</Text>
          </Center>
        </Card>
      )}

      {results?.org && (
        <OrgsCard
          type='data'
          input={results.org}
          global={global}
          projection='default'
        />
      )}

      {results?.project && (
        <ProjectsCard
          type='data'
          input={results.project}
          global={global}
          projection='adminGlobalPage'
        />
      )}

      {results?.site && (
        <SitesCard
          type='data'
          input={results.site}
          global={global}
          projection='adminView'
        />
      )}

      {results?.cable && (
        <CablesCard
          type='data'
          input={results.cable}
          global={global}
          projection='adminGlobalPage'
        />
      )}

      {results?.logger && (
        <LoggersCard
          type='data'
          input={results.logger}
          global={global}
          projection='adminGlobalPage'
        />
      )}

      {results?.modem && (
        <ModemsCard
          type='data'
          input={results.modem}
          global={global}
          projection='default'
        />
      )}

      {results?.user && (
        <Users
          data={results.user}
          global={global}
          globalFilter={globalFilter}
        />
      )}
    </>
  )
}

const SearchTooltipText = () => (
  <Box>
    Enter search terms separated by a comma. Terms shorter than 3 characters
    will be ignored.
    <br />
    <br />
    <Text as='span' fontWeight='semibold'>
      Text searches:
    </Text>
    <ul>
      <li>org name</li>
      <li>project name</li>
      <li>site name</li>
      <li>cable name</li>
    </ul>
    <br />
    <Text as='span' fontWeight='semibold'>
      Number searches:
    </Text>
    <ul>
      <li>cable serial</li>
      <li>site that has that cable serial</li>
      <li>incremental portion of logger serial</li>
    </ul>
    <br />
    <Text as='span' fontWeight='semibold'>
      Extra Nuances
    </Text>
    <br />
    Search terms starting with DLB, D405, D505, or D605 will be treated as
    logger serials. These searches will return the logger and the site that has
    that logger, in addition to text matches as described above.
    <br />
    <br />
    Numerical searches that are 15 characters long will be treated as an IMEI.
    These searches will return the loggers and sites that have that IMEI, in
    addition to text and number matches as described above.
  </Box>
)

export const SearchTooltip = () => (
  <Tooltip label={<SearchTooltipText />}>
    <QuestionIcon left='-32px' position='relative' />
  </Tooltip>
)

interface SearchInputProps {
  isMobile?: boolean
}

export const SearchInput = ({ isMobile }: SearchInputProps) => {
  const { globalFilter } = useSearchParams()
  const { history } = useRouter()
  const [term, setTerm] = useState(globalFilter ?? '')
  const [value, setValue] = useState(term ?? '')

  useEffect(() => {
    if (term.length >= 3) {
      history.push(`/search?q=${term}`)
    }
  }, [history, term])

  const handler = useKeyboardShortcut('Enter', () => setTerm(value))

  const searchKey = useKeyboardShortcut(
    '/',
    () => {
      const input = document.querySelector<HTMLInputElement>('#search-input')
      input?.focus()
    },
    {
      preventDefault: true,
      skipHandler   : (e: KeyboardEvent) => {
        if (e.target instanceof HTMLInputElement) return true
        if (e.target instanceof HTMLTextAreaElement) return true
        return false
      },
    },
  )

  useEffect(() => {
    window.addEventListener('keydown', searchKey)
    return () => window.removeEventListener('keydown', searchKey)
  }, [searchKey])

  return (
    <HStack>
      <InputGroup>
        <Input
          onChange={(e) => setValue(e.target.value)}
          value={value}
          onKeyDown={handler as unknown as React.KeyboardEventHandler}
          placeholder='Search'
          id='search-input'
        />
        {!isMobile && (
          <InputRightElement>
            <SearchTooltip />
          </InputRightElement>
        )}
        {!isMobile && (
          <InputRightElement pr='76px' pb='3px'>
            <span>
              <Kbd>/</Kbd>
            </span>
          </InputRightElement>
        )}
        <InputRightElement>
          <IconButton
            size='sm'
            aria-label='Search'
            icon={<SearchIcon />}
            onClick={() => setTerm(value)}
          />
        </InputRightElement>
      </InputGroup>
    </HStack>
  )
}
