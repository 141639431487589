import {
  TriangleDownIcon,
  TriangleUpIcon,
  ArrowLeftIcon,
  ArrowRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@chakra-ui/icons'
import {
  Center,
  chakra,
  HStack,
  IconButton,
  Box,
  Image,
  Input,
  Select,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useColorModeValue,
} from '@chakra-ui/react'
import type { TableProps } from '@chakra-ui/react'
import { Parser } from '@json2csv/plainjs'
import { flatten } from '@json2csv/transforms'
import { matchSorter } from 'match-sorter'
import { useMemo } from 'react'
import {
  useGlobalFilter,
  useSortBy,
  usePagination,
  useTable,
  useExpanded,
} from 'react-table'
import 'regenerator-runtime'

import status_green from 'assets/status_green.svg'
import status_red from 'assets/status_red.svg'

import { filterObject, DownloadColumn } from 'lib/getObjectKeys'

import * as filters from './filters'

export function fuzzyTextFilterFn(
  rows: any[],
  ids: any[],
  filterValue: string,
) {
  if (ids.length < 1) return rows

  let terms
  if (filterValue.includes(',')) terms = filterValue.split(',')
  else terms = filterValue.split(' ')

  const result = terms.reduceRight(
    (results: any[], term: string) =>
      matchSorter(results, term, {
        keys: [
          (row) => {
            return ids.reduce((result, id) => {
              if (typeof row.values[id] === 'object') {
                try {
                  Object.keys(row.values[id]).forEach((k) => {
                    result.push(row.values[id][k])
                  })
                }
                catch (e) {
                  result.push(row.values[id])
                }
              }
              else {
                result.push(row.values[id])
              }
              return result
            }, [])
          },
        ],
        threshold: matchSorter.rankings.CONTAINS,
      }),
    rows,
  )

  return result
}

fuzzyTextFilterFn.autoRemove = (val: any) => !val

interface IDataTable extends TableProps {
  data: any[]
  columns: any[]
  initialGlobalFilter?: string
  hiddenColumns?: string[]
  downloadColumns?: DownloadColumn[]
  initialPageSize?: number
  hideGlobalFilter?: boolean
  download?: any
  projection?: string
  sortBy?: any
}

export const DataTable = ({
  data,
  columns,
  initialGlobalFilter,
  initialPageSize = pageSizeOptions[0],
  hideGlobalFilter = false,
  hiddenColumns = [],
  downloadColumns = [],
  download = {
    filename: 'beadedcloud-data.csv',
  },
  sortBy = [],
  projection = '',
  ...rest
}: IDataTable) => {
  const filterTypes = useMemo(
    () => ({
      fuzzyText: fuzzyTextFilterFn,
      text     : (rows: any[], id: any, filterValue: string) => {
        return rows.filter((row) => {
          const rowValue = row.values[id]
          return rowValue !== undefined
            ? String(rowValue)
              .toLowerCase()
              .startsWith(String(filterValue).toLowerCase())
            : true
        })
      },
    }),
    [],
  )

  const bgColor = useColorModeValue('white', 'gray.700')
  const defaultColumn = useMemo(() => ({ Filter: filters.Default }), [])

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    // visibleColumns,
    preGlobalFilteredRows,
    setGlobalFilter,
    rows,

    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,

    state: { pageIndex, pageSize, globalFilter },
  } = useTable(
    {
      columns,
      data,
      initialState: {
        globalFilter: initialGlobalFilter,
        pageSize    : initialPageSize,
        hiddenColumns,
        sortBy,
      },
      defaultColumn,
      filterTypes,
      globalFilter: 'fuzzyText',
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  )

  download.fn = async () => {
    try {
      const parser = new Parser({
        transforms: [flatten({ arrays: true, objects: true })],
      })
      const csv = parser.parse(
        rows.map(({ original }) => {
          if (downloadColumns.length === 0) return original

          return filterObject(original, downloadColumns)
        }),
      )

      const downloadLink = document.createElement('a')
      downloadLink.setAttribute(
        'href',
        'data:text/csv;charset=utf-8,' + encodeURIComponent('\uFEFF' + csv),
      )
      downloadLink.setAttribute('download', download.filename)
      document.body.appendChild(downloadLink)
      downloadLink.click()
      downloadLink.remove()
    }
    catch (err) {
      console.error(err)
    }
  }

  return (
    <Box>
      {!hideGlobalFilter && (
        <filters.Global
          preGlobalFilteredRows={preGlobalFilteredRows}
          globalFilter={globalFilter}
          setGlobalFilter={setGlobalFilter}
        />
      )}
      <Box overflowX='auto'>
        <Table {...getTableProps()} size='sm' {...rest}>
          <colgroup>
            {headerGroups.length >= 2
              ? headerGroups[1].headers.map((h) => (
                <col key={h.id} className={h.id} />
              ))
              : headerGroups[0].headers.map((h) => (
                <col key={h.id} className={h.id} />
              ))}
          </colgroup>

          <Thead>
            {headerGroups.map((headerGroup) => (
              <Tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <Th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    isNumeric={(column as any)?.isNumeric}
                    sx={
                      (column as any)?.isCentered ? { textAlign: 'center' } : {}
                    }
                    {...((column as any)?.isSticky
                      ? {
                        position: 'sticky',
                        right   : '0px',
                        bgColor,
                        opacity : 0.85,
                      }
                      : {})}
                  >
                    {column.render('Header')}
                    {column.canSort !== false && (
                      <chakra.span pl='4'>
                        {column?.isSorted ? (
                          column?.isSortedDesc ? (
                            <TriangleDownIcon aria-label='sorted descending' />
                          ) : (
                            <TriangleUpIcon aria-label='sorted ascending' />
                          )
                        ) : null}
                      </chakra.span>
                    )}
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row: any) => {
              prepareRow(row)
              return (
                <Tr {...row.getRowProps()}>
                  {row.cells.map((cell: any) => {
                    if (cell.column.isStatus)
                      return (
                        <Td
                          {...cell.getCellProps([
                            {
                              ...cell.row.original?._customProps?.(cell),
                            },
                          ])}
                          isNumeric={cell.column.isNumeric}
                        >
                          <Center>
                            <Image
                              w='4'
                              h='4'
                              src={cell.value ? status_green : status_red}
                              alt={`${cell.value}`}
                            />
                          </Center>
                        </Td>
                      )

                    return (
                      <Td
                        {...cell.getCellProps([
                          {
                            ...cell.row.original?._customProps?.(cell),
                          },
                        ])}
                        isNumeric={cell.column.isNumeric}
                        {...(cell.column.isSticky
                          ? {
                            position: 'sticky',
                            right   : '0px',
                            bgColor,
                            opacity : 0.85,
                          }
                          : {})}
                      >
                        {cell.column.isCentered ? (
                          <Center>{cell.render('Cell')}</Center>
                        ) : (
                          cell.render('Cell')
                        )}
                      </Td>
                    )
                  })}
                </Tr>
              )
            })}
          </Tbody>
        </Table>
        <Center>
          {data.length > pageSizeOptions[0] /* pageSize */ && (
            <Pagination
              firstPage={() => gotoPage(0)}
              previousPage={() => previousPage()}
              nextPage={() => nextPage()}
              lastPage={() => gotoPage(pageCount - 1)}
              pageIndex={pageIndex}
              changePage={(e) => {
                gotoPage(e.target.value ? Number(e.target.value) - 1 : 0)
              }}
              pageSize={pageSize}
              changePageSize={(e) => {
                setPageSize(Number(e.target.value))
              }}
              pageOptions={pageOptions}
              canNextPage={canNextPage}
              canPreviousPage={canPreviousPage}
            />
          )}
        </Center>
      </Box>
    </Box>
  )
}

interface PaginationProps {
  firstPage: () => void
  previousPage: () => void
  nextPage: () => void
  lastPage: () => void
  pageIndex: number
  changePage: (e: React.ChangeEvent<HTMLInputElement>) => void
  pageSize: number
  changePageSize: (e: React.ChangeEvent<HTMLSelectElement>) => void
  pageOptions: number[]
  canNextPage: boolean
  canPreviousPage: boolean
}

const Pagination = ({
  firstPage,
  previousPage,
  nextPage,
  lastPage,
  pageIndex,
  changePage,
  pageSize,
  changePageSize,
  pageOptions,
  canPreviousPage,
  canNextPage,
}: PaginationProps) => {
  return (
    <VStack>
      <HStack pt='0.5rem'>
        <IconButton
          size='sm'
          disabled={!canPreviousPage}
          onClick={firstPage}
          icon={<ArrowLeftIcon />}
          aria-label='first page'
          aria-disabled={!canPreviousPage}
        />
        <IconButton
          size='sm'
          disabled={!canPreviousPage}
          onClick={previousPage}
          icon={<ChevronLeftIcon fontSize='2rem' />}
          aria-label='previous page'
          aria-disabled={!canPreviousPage}
        />
        <IconButton
          size='sm'
          disabled={!canNextPage}
          onClick={nextPage}
          icon={<ChevronRightIcon fontSize='2rem' />}
          aria-label='next page'
          aria-disabled={!canNextPage}
        />
        <IconButton
          size='sm'
          disabled={!canNextPage}
          onClick={lastPage}
          icon={<ArrowRightIcon />}
          aria-label='last page'
          aria-disabled={!canNextPage}
        />
      </HStack>
      <HStack>
        <Input
          type='number'
          value={pageIndex + 1}
          onChange={changePage}
          w='4rem'
          aria-label='change page'
        />
        <Text> of {pageOptions.length}</Text>
      </HStack>
      <Select
        value={pageSize}
        onChange={changePageSize}
        aria-label='select page'
      >
        {pageSizeOptions.map((entry) => (
          <option key={entry} value={entry}>
            Show {entry}
          </option>
        ))}
      </Select>
    </VStack>
  )
}

const pageSizeOptions = [100, 500, 1000]
