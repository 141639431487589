import {
  Button,
  Code,
  IconButton,
  Center,
  Tooltip,
  Text,
  Input,
} from '@chakra-ui/react'
import type { FocusableElement } from '@chakra-ui/utils'
import { useRef } from 'react'
import { FaTrashAlt } from 'react-icons/fa'

import { ErrorWrapper } from 'components/ErrorWrapper'

import { ModalContainer } from 'containers/ModalContainer'

import { useInput } from 'hooks/useInput'

type DeleteModalProps = {
  data: any
  iconButtonProps?: any
  deleteFn: React.MouseEventHandler
  getName: (data: any) => string
}

export const DeleteModal = ({
  data,
  iconButtonProps,
  deleteFn,
  getName,
}: DeleteModalProps) => {
  const cancelRef = useRef<FocusableElement>()
  const [verify, setVerify] = useInput('')
  const name = getName(data)

  return (
    <ErrorWrapper>
      <ModalContainer
        TriggerButton={({ onClick }) => (
          <>
            <Tooltip label={'Delete'}>
              <Center>
                <IconButton
                  icon={<FaTrashAlt />}
                  onClick={onClick}
                  {...iconButtonProps}
                />
              </Center>
            </Tooltip>
          </>
        )}
        header={'Delete Item'}
        footer={({ onClose }) => (
          <>
            <Button
              ref={cancelRef as React.LegacyRef<HTMLButtonElement>}
              mr={3}
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              variant='ghost'
              color='red'
              onClick={deleteFn}
              tabIndex={-1}
              disabled={verify !== name}
            >
              Delete
            </Button>
          </>
        )}
      >
        <Text pb='1rem'>
          Are you sure you want to delete <Code>{name}</Code>?
          <br />
          Enter the name to confirm.
        </Text>
        <Input role='verify' value={verify} onChange={setVerify} />
      </ModalContainer>
    </ErrorWrapper>
  )
}
