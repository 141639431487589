import merge from 'lodash.merge'
import type { QueryKey, QueryFunction, QueryOptions } from 'react-query'

import { org } from 'api'

import { alphabeticalByName } from 'forms/helpers/alphabeticalByField'
import { useAsyncSelectOptions } from 'forms/helpers/useAsyncSelectOptions'
import { SelectInput, SelectInputProps } from 'forms/inputs/SelectInput'

import { useUserContext } from 'hooks/useUserContext'

type OrganizationOptions = {
  name: string
  value: number
}

export const useOrgSelectOptions = () => {
  const { superUser } = useUserContext()

  const queryArgs: [QueryKey, QueryFunction, QueryOptions?] =
    superUser === 'on'
      ? [
        ['org', { superUser, projection: 'menu' }],
        () => org.admin.getMenuProjection(),
      ]
      : [['org', { projection: 'menu' }], () => org.getMenuProjection()]

  const { options, ...rest } = useAsyncSelectOptions<OrganizationOptions>({
    queryArgs,
    dataKey: 'org',
    mapFn  : (org: any) => ({
      name : org.organizationName,
      value: org.id,
    }),
    sortFn: alphabeticalByName,
  })

  return {
    options: options ?? [],
    ...rest,
  }
}

export const SelectOrganization = (
  props: Omit<SelectInputProps, 'options'>,
) => {
  const { options } = useOrgSelectOptions()

  return (
    <SelectInput
      name={props.name}
      label={props.label}
      options={options}
      placeholder={props.placeholder ?? 'Select an organization'}
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: true,
      })}
      hidden={props.hidden}
    />
  )
}
