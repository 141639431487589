import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.cloudloop_data_usage_ */
export type CloudloopDataUsageId = string & { __brand: 'CloudloopDataUsageId' }

/** Represents the table public.cloudloop_data_usage_ */
export default interface CloudloopDataUsageTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    CloudloopDataUsageId,
    CloudloopDataUsageId | undefined,
    CloudloopDataUsageId
  >

  /** Database type: pg_catalog.text */
  imei: ColumnType<string, string, string>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.int4 */
  usageMonth: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  usageYear: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  bytesUsed: ColumnType<number, number, number>

  /** Database type: pg_catalog.int4 */
  bytesAllocated: ColumnType<number, number, number>

  /** Database type: pg_catalog.text */
  contractId: ColumnType<string | null, string | null, string | null>
}

export type CloudloopDataUsage = Selectable<CloudloopDataUsageTable>

export type CloudloopDataUsageInitializer = Insertable<CloudloopDataUsageTable>

export type CloudloopDataUsageMutator = Updateable<CloudloopDataUsageTable>

export const cloudloopDataUsageId =
  z.string() as unknown as z.Schema<CloudloopDataUsageId>

export const cloudloopDataUsage = z.object({
  id            : cloudloopDataUsageId,
  imei          : z.string(),
  updatedAt     : z.coerce.date(),
  usageMonth    : z.number(),
  usageYear     : z.number(),
  bytesUsed     : z.number(),
  bytesAllocated: z.number(),
  contractId    : z.string().nullable().optional(),
}) as unknown as z.Schema<CloudloopDataUsage>

export const cloudloopDataUsageInitializer = z.object({
  id            : cloudloopDataUsageId.optional(),
  imei          : z.string(),
  updatedAt     : z.coerce.date(),
  usageMonth    : z.number(),
  usageYear     : z.number(),
  bytesUsed     : z.number(),
  bytesAllocated: z.number(),
  contractId    : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<CloudloopDataUsageInitializer>

export const cloudloopDataUsageMutator = z.object({
  id            : cloudloopDataUsageId.optional(),
  imei          : z.string().optional(),
  updatedAt     : z.coerce.date().optional(),
  usageMonth    : z.number().optional(),
  usageYear     : z.number().optional(),
  bytesUsed     : z.number().optional(),
  bytesAllocated: z.number().optional(),
  contractId    : z.string().optional().nullable().optional(),
}) as unknown as z.Schema<CloudloopDataUsageMutator>
