import { z } from 'zod'

/** Represents the enum public.logger_condition_type_ */
enum LoggerConditionType {
  SOLD = 'SOLD',
  LEASE = 'LEASE',
  READY_FOR_SALE = 'READY_FOR_SALE',
  TESTING = 'TESTING',
  IN_STORAGE = 'IN_STORAGE',
  DAMAGED = 'DAMAGED',
  UNBUILT = 'UNBUILT',
  UNKNOWN = 'UNKNOWN',
  LOST = 'LOST',
  NONE = 'NONE',
}

export default LoggerConditionType

/** Zod schema for logger_condition_type_ */
export const loggerConditionType = z.enum([
  'SOLD',
  'LEASE',
  'READY_FOR_SALE',
  'TESTING',
  'IN_STORAGE',
  'DAMAGED',
  'UNBUILT',
  'UNKNOWN',
  'LOST',
  'NONE',
])
