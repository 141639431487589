import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputLeftAddon,
  InputRightAddon,
  Text,
} from '@chakra-ui/react'
import { useFormContext } from 'react-hook-form'
import NumberFormat from 'react-number-format'

import { BaseInputProps } from 'forms/helpers/types'

export type MaskedInputProps = BaseInputProps & {
  type?: string
  readonly?: boolean
  right?: string | JSX.Element | ((props: any) => JSX.Element)
  left?: string | JSX.Element | ((props: any) => JSX.Element)
  mask?: string
  format: string
}
export const MaskedInput = (props: MaskedInputProps) => {
  const {
    register,
    watch,
    setValue,
    formState: { errors },
  } = useFormContext()

  const value = watch(props.name)

  const fieldProps = register(props.name, props.hookformProps)

  const handleChange = (e: any) => {
    setValue(props.name, e.target.value)
  }

  return (
    <FormControl
      isRequired={Boolean(props?.hookformProps?.required)}
      isInvalid={Boolean(errors[props.name])}
    >
      <FormLabel htmlFor={props.name}>{props.label ?? props.name}</FormLabel>
      {props.description && <Text>{props.description}</Text>}
      <InputGroup>
        {props.left && <InputLeftAddon children={props.left} />}
        <Input
          as={NumberFormat}
          autoComplete='off'
          hidden={props.hidden ?? false}
          readOnly={props.readonly ?? false}
          type={props.type ?? 'text'}
          placeholder={props.placeholder ?? props.name}
          format={props.format}
          {...fieldProps}
          onChange={handleChange}
          value={value}
          allowEmptyFormatting={true}
          mask={props.mask ?? '_'}
        />
        {props.right && <InputRightAddon children={props.right} />}
      </InputGroup>
      <FormErrorMessage>{errors[props.name]?.message}</FormErrorMessage>
    </FormControl>
  )
}
