import * as cable from '@beaded/js-validate/lib/validate/cable'
import * as cablePrices from '@beaded/js-validate/lib/validate/cablePrices'
import * as sensor from '@beaded/js-validate/lib/validate/sensor'
import * as site from '@beaded/js-validate/lib/validate/site'
import * as siteEvent from '@beaded/js-validate/lib/validate/siteEvent'

export const cableSchema = cable.cable
export const cableEventSchema = cable.cableEvent
export const cableUseSchema = cable.cableUse
export const cablePricesSchema = cablePrices.cablePrices
export const siteSchema = site.site
export const cableUseEntrySchema = site.cableUseEntry
export const siteEventSchema = siteEvent.siteEvent
export const sensorSchema = sensor.sensor
