import { AnyZodObject, z } from 'zod'

import {
  Logger,
  loggerEvent,
  LoggerEventInitializer,
  loggerHistory,
  LoggerHistoryInitializer,
  loggerHistoryMutator,
  LoggerHistoryMutator,
  LoggerId,
  loggerInitializer,
  LoggerInitializer,
  loggerMutator,
  LoggerMutator,
  modemHistory,
  ModemHistoryInitializer,
  ModemId,
  organizationId,
  projectId,
  UserId,
  LoggerHistoryView,
  LoggerModemsView,
  LoggerEventsView,
  LoggerCurrentModemView,
  LoggerLastTransmissionView,
  Subscriber,
  LoggerCurrentHistoryView,
  LoggerCloudloopView,
} from '../../index.js'

export type LoggerWithContextInitializer = LoggerInitializer &
  (
    | {
        historyAction: 'appendHistory'
        history: Omit<LoggerHistoryInitializer, 'id' | 'loggerId'>
      }
    | {
        historyAction: 'populateHistory'
        history: Omit<LoggerHistoryInitializer, 'id' | 'loggerId'>[]
      }
    | { historyAction?: undefined; history?: undefined }
  ) &
  (
    | {
        eventsAction: 'appendEvents'
        events: Omit<LoggerEventInitializer, 'id' | 'loggerId'>
      }
    | {
        eventsAction: 'populateEvents'
        events: Omit<LoggerEventInitializer, 'id' | 'loggerId'>[]
      }
    | { eventsAction?: undefined; events?: undefined }
  ) &
  (
    | {
        modemAction: 'appendModems'
        modems: Omit<ModemHistoryInitializer, 'loggerId'> & {
          imei: string
          createdBy?: UserId
          createdAt?: Date | string
          modemId: never
        }
      }
    | {
        modemAction: 'populateModems'
        modems: Array<
          Omit<ModemHistoryInitializer, 'loggerId'> & {
            imei: string
            createdBy: UserId
            createdAt: Date | string
            modemId: never
          }
        >
      }
    | {
        modemAction: 'createIfNotExists'
        modems: Array<
          Omit<ModemHistoryInitializer, 'loggerId' | 'modemId'> & {
            imei: string
            modemId?: ModemId
            createdBy?: UserId
            createdAt?: Date | string
          }
        >
      }
    | {
        modemAction?: undefined
        modems?: undefined
      }
  )

const loggerContextInitializerFields = z.object({
  historyAction: z.string().optional(),
  history      : z
    .array(
      z.object({
        startedAt     : z.coerce.date(),
        endedAt       : z.coerce.date().nullable().optional(),
        condition     : z.string().optional(),
        organizationId: organizationId,
        projectId     : projectId,
      }),
    )
    .optional(),
  eventsAction: z.string().optional(),
  events      : z
    .array(
      z.object({
        eventType  : z.string(),
        eventDate  : z.coerce.date(),
        raw        : z.string().optional(),
        text       : z.string().optional(),
        cableSerial: z.number().nullable().optional(),
        onewireId  : z.string().nullable().optional(),
      }),
    )
    .optional(),
  modemAction: z.string().optional(),
  modems     : z
    .array(
      z.object({
        modemId  : z.number().optional(),
        imei     : z.string(),
        startedAt: z.coerce.date(),
        endedAt  : z.coerce.date().nullable().optional(),
        notes    : z.string().optional(),
      }),
    )
    .optional(),
})

export const loggerWithContextInitializer =
  loggerContextInitializerFields.merge(
    loggerInitializer as unknown as AnyZodObject,
  ) as unknown as z.Schema<LoggerWithContextInitializer>

export type EmbeddedLoggerHistory = LoggerHistoryView | LoggerCurrentHistoryView

export type EmbeddedLoggerEvent = LoggerEventsView

export type EmbeddedLoggerModem = LoggerModemsView

export type EmbeddedCurrentModem = LoggerCurrentModemView

export type EmbeddedLoggerLastTransmission = LoggerLastTransmissionView

export type EmbeddedCloudloopCache = Omit<
  LoggerCloudloopView,
  'updatedAt' | 'cacheData'
> & {
  updatedAt: Date | string
  cacheData: { subscriber?: Subscriber }
}

export type LoggerWithContext = Logger & {
  history: EmbeddedLoggerHistory[]
  events: EmbeddedLoggerEvent[]
  currentModem?: EmbeddedCurrentModem
  modems?: EmbeddedLoggerModem[]
  cloudloop: EmbeddedCloudloopCache | null
  lastTransmission?: EmbeddedLoggerLastTransmission | null
}

const loggerContextFields = z.object({
  history: z.array(
    z
      .object({
        loggerSerial: z.string(),
      })
      .merge(loggerHistory as unknown as AnyZodObject),
  ),
  events: z.array(loggerEvent),
  modems: z.array(
    z
      .object({
        networkStatus: z.string(),
        imei         : z.string(),
        platform     : z.string(),
        provider     : z.string(),
      })
      .merge(modemHistory as unknown as AnyZodObject),
  ),
  cloudloop: z.object({
    updatedAt    : z.coerce.date(),
    cacheData    : z.unknown(),
    hardwareId   : z.string(),
    subscriberId : z.string(),
    networkStatus: z.string(),
  }),
  lastTransmission: z.object({
    battery            : z.number().nullable().optional(),
    batteryTrend       : z.unknown().nullable().optional(),
    msgSize            : z.number().nullable().optional(),
    errors85           : z.number().nullable().optional(),
    errors99           : z.number().nullable().optional(),
    errors125          : z.number().nullable().optional(),
    numConnectedDevices: z.number().nullable().optional(),
    recievedAt         : z.coerce.date(),
  }),
})

export const loggerWithContext = loggerContextFields.merge(
  loggerInitializer as unknown as AnyZodObject,
) as unknown as z.Schema<LoggerWithContext>

export type LoggerModel = 'D605' | 'SL' | 'D505' | 'DLB' | 'NGT'

export const LoggerModels = ['D605', 'SL', 'D505', 'DLB', 'NGT']

export const loggerModels = z.enum(['D605', 'SL', 'D505', 'DLB', 'NGT'])

export type LoggerWithContextMutator = LoggerMutator & {
  id: LoggerId
} & {
  history?: EmbeddedLoggerHistory[] | null
  modems?: EmbeddedLoggerModem[] | null
  events?: EmbeddedLoggerEvent[] | null
  cloudloop?: EmbeddedCloudloopCache | null
  lastTransmission?: EmbeddedLoggerLastTransmission | null
  newModem?: Omit<ModemHistoryInitializer, 'loggerId' | 'modemId'> & {
    imei: string
    modemId?: ModemId
    createdBy?: UserId
    createdAt?: Date | string
  }
}

const loggerContextMutatorFields = z.object({
  newModem: z
    .object({
      modemId  : z.number().optional(),
      imei     : z.string(),
      startedAt: z.coerce.date().nullable().optional(),
      endedAt  : z.coerce.date().nullable().optional(),
      notes    : z.string().nullable().optional(),
    })
    .optional(),
})

export const loggerWithContextMutator = loggerContextMutatorFields.merge(
  loggerMutator as unknown as AnyZodObject,
) as unknown as z.Schema<LoggerWithContextMutator>

export const bulkLoggerHistoryMutator = z.array(
  loggerHistoryMutator,
) as unknown as z.Schema<LoggerHistoryMutator[]>
