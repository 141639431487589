import memoize from 'memoizee'

import { cable, org, project, site, user, logger } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import { getLastCableUse, getLastLoggerUse } from 'lib/lastUse'

export const useCableSelectOptions = (filter, values) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['cable', { superUser, projection: 'menu' }],
        () => cable.admin.getMenuProjection(),
      ]
      : [['cable', { projection: 'menu' }], () => cable.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.cables
    ?.map(({ serial, use }) => {
      const lastUse = getLastCableUse(use)
      return {
        name   : serial,
        value  : serial,
        org    : lastUse?.org,
        project: lastUse?.project,
      }
    })
    ?.filter(({ value }) => Boolean(value))
    .sort(alphabeticalByName)

  if (filter && values && data?.cables) {
    options = filter(values, options)
  }

  options?.unshift({ name: 'Air Temp', value: 1 })
  options?.unshift({ name: 'Cable Placeholder', value: 2 })

  return {
    options,
    ...rest,
  }
}

export const useCableMultiValueSelectOptions = (filter, values) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['cable', { superUser, projection: 'menu' }],
        () => cable.admin.getMenuProjection(),
      ]
      : [['cable', { projection: 'menu' }], () => cable.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.cables
    ?.map(({ id, serial, use }) => {
      const lastUse = getLastCableUse(use)
      return {
        name   : serial,
        value  : { serial, id },
        org    : lastUse?.org,
        project: lastUse?.project,
      }
    })
    ?.filter(({ value }) => value.serial)
    .sort(alphabeticalByName)

  if (filter && values && data?.cables) {
    options = filter(values, options)
  }

  return {
    options,
    ...rest,
  }
}

export const useCableIdSelectOptions = (filter, values) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['cable', { superUser, projection: 'menu' }],
        () => cable.admin.getMenuProjection(),
      ]
      : [['cable', { projection: 'menu' }], () => cable.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.cables
    ?.map(({ id, serial, use }) => ({
      name : serial,
      value: Array.isArray(id) ? id[0] : id,
      org  : getLastCableUse(use)?.org,
    }))
    ?.filter(({ name }) => name !== null)
    .sort(alphabeticalByName)

  if (filter && values && data?.cables) {
    options = filter(values, options)
  }

  return {
    options,
    ...rest,
  }
}

export const useProjectSelectOptions = (filter, values, flags) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['project', { superUser, projection: 'menu' }],
        () => project.admin.getMenuProjection(),
      ]
      : [['project', { projection: 'menu' }], () => project.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.proj
    ?.map(({ id, projectName, organizationId }) => ({
      name : projectName,
      value: id,
      organizationId,
      org  : {
        id: organizationId,
      },
    }))
    .sort(alphabeticalByName)

  if (filter && values && data?.proj) {
    options = filter(values, options, flags)
  }

  return {
    options,
    ...rest,
  }
}

export const useOrgSelectOptions = (filter, values, remapOpts) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['org', { superUser, projection: 'menu' }],
        () => org.admin.getMenuProjection(),
      ]
      : [['org', { projection: 'menu' }], () => org.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.org
    ?.map(({ id, organizationName }) => ({
      name : organizationName,
      value: id,
    }))
    .sort(alphabeticalByName)

  if (options && typeof remapOpts === 'function')
    options = options.map(remapOpts)

  return {
    options,
    ...rest,
  }
}

export const useSiteSelectOptions = (filter, values) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['site', { superUser, projection: 'menu' }],
        () => site.admin.getMenuProjection(),
      ]
      : [['site', { projection: 'menu' }], () => site.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.sites
    ?.map(({ id, name, project }) => ({
      name : name ?? id,
      value: id,
      project,
    }))
    .sort(alphabeticalByName)

  if (filter && values && data?.sites) {
    options = filter(values, options)
  }

  return {
    options,
    ...rest,
  }
}

export const useSiteSelectOptionsWithImei = (filter, values) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        [
          'site',
          {
            superUser,
            projection  : 'menu',
            'project.id': values?.project?.id,
          },
        ],
        () =>
          site.admin.getMenuProjection({
            params: { 'project.id': values?.project?.id },
          }),
      ]
      : [['site', { projection: 'menu' }], () => site.getMenuProjection()]
  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.sites
    ?.map(({ id, name, project, imei }) => ({
      name : name ?? id,
      value: { id, imei },
      project,
      imei,
    }))
    .sort(alphabeticalByName)

  if (filter && values && data?.sites) {
    options = filter(values, options)
  }

  return {
    options,
    ...rest,
  }
}

export const useUserSelectOptions = () => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['user', { superUser, projection: 'menu' }],
        () => user.admin.getMenuProjection(),
      ]
      : [['user', { projection: 'menu' }], () => user.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  const options = data?.users
    ?.map(({ id, name }) => ({
      name,
      value: id,
    }))
    .sort(alphabeticalByName)

  return {
    options,
    ...rest,
  }
}

export const useLoggerMultiValueSelectOptions = (filter, values) => {
  const { superUser } = useUserContext()

  const queryArgs =
    superUser === 'on'
      ? [
        ['logger', { superUser, projection: 'menu' }],
        () => logger.admin.getMenuProjection(),
      ]
      : [['logger', { projection: 'menu' }], () => logger.getMenuProjection()]

  const { data, ...rest } = useQuery(...queryArgs)

  let options = data?.loggers
    ?.reduce((output, { id, currentModem, loggerSerial, history }) => {
      const lastUse = getLastLoggerUse(history)
      if (currentModem !== undefined && loggerSerial !== undefined)
        output.push({
          name:
            currentModem?.imei !== undefined
              ? loggerSerial
              : `${loggerSerial}  * no modem`,
          value : loggerSerial,
          extras: JSON.stringify({
            id,
            loggerSerial,
            imei: currentModem?.imei,
          }),
          imei          : currentModem?.imei,
          organizationId: lastUse?.organizationId,
          projectId     : lastUse?.projectId,
        })
      return output
    }, [])
    .sort(alphabeticalByName)

  if (filter && values && data?.loggers) {
    options = filter(values, options)
  }

  if (values && data?.loggers) {
    options = remapLoggerOptions(values, options)
  }

  return {
    options,
    ...rest,
  }
}

export const alphabeticalByName = (a, b) => {
  if (a.name < b.name) {
    return -1
  }
  if (a.name > b.name) {
    return 1
  }
  return 0
}

export const filterProjectsByOrgId = memoize((values, options) => {
  const orgId = parseInt(
    values?.org?.id ?? values?.extras?.org?.id ?? values.organizationId,
    10,
  )
  return options.filter(
    (opt) =>
      (opt?.org?.id !== undefined && opt.org.id === orgId) ||
      (opt.organizationId !== undefined && opt.organizationId === orgId),
  )
})

export const filterProjectsByUseOrgId = memoize(
  (values, options, { index }) => {
    if (values?.use?.[index]?.org?.id) {
      const orgId = parseInt(values.use[index].org.id, 10)
      return options.filter((opt) => opt?.org?.id === orgId)
    }
    return options
  },
)

export const filterSitesByProjectId = (values, options) => {
  const projId = parseInt(values?.project?.id, 10)
  return options.filter((opt) => opt?.project?.id === projId)
}

export const filterCablesByOrgId = memoize((values, options) => {
  if (values?.org?.id !== undefined) {
    return options.filter(
      (opt) =>
        (opt?.org?.id !== undefined && opt.org.id === values.org.id) ||
        opt.value === 1,
    )
  }

  if (values?.extras?.org?.id !== undefined) {
    return options.filter(
      (opt) =>
        (opt?.org?.id !== undefined && opt.org.id === values.extras.org.id) ||
        opt.value === 1,
    )
  }

  return options
})

export const filterCablesByProjectId = memoize((values, options) => {
  if (values?.project?.id !== undefined) {
    return options.filter(
      (opt) =>
        (opt?.project?.id !== undefined &&
          opt.project.id === values.project.id) ||
        (opt?.projectId !== undefined && opt.projectId === values.project.id) ||
        opt.value === 1,
    )
  }

  if (values?.extras?.project?.id !== undefined) {
    return options.filter(
      (opt) =>
        (opt?.project?.id !== undefined &&
          opt.project.id === values.extras.project.id) ||
        opt.value === 1,
    )
  }

  return options
})

export const filterLoggersByOrgId = memoize((values, options) => {
  if (values?.org?.id !== undefined) {
    return options.filter(
      (opt) => opt?.org?.id !== undefined && opt?.org?.id === values.org.id,
    )
  }
  else if (values?.extras?.org?.id !== undefined) {
    return options.filter(
      (opt) =>
        opt?.org?.id !== undefined && opt?.org?.id === values.extras.org.id,
    )
  }

  return options
})

export const filterLoggersByProjectId = memoize((values, options) => {
  if (values?.project?.id !== undefined) {
    return options.filter(
      (opt) =>
        (opt?.project?.id !== undefined &&
          opt.project.id === values.project.id) ||
        (opt?.projectId !== undefined && opt.projectId === values.project.id),
    )
  }
  else if (values?.extras?.project?.id !== undefined) {
    return options.filter(
      (opt) =>
        opt?.project?.id !== undefined &&
        opt?.project?.id === values.extras.project.id,
    )
  }

  return options
})

export const filterModemsByOrgId = memoize((values, options) => {
  if (values?.org?.id !== undefined) {
    return options.filter(
      (opt) => opt?.org?.id !== undefined && opt?.org?.id === values.org.id,
    )
  }

  return options
})

export const filterModemsByProjectId = memoize((values, options) => {
  if (values?.project?.id !== undefined) {
    return options.filter(
      (opt) =>
        opt?.project?.id !== undefined &&
        opt?.project?.id === values.project.id,
    )
  }

  return options
})

export const filterLoggerMultiValueByOrgId = memoize((values, options) => {
  if (values?.org?.id !== undefined) {
    return options.filter(
      (opt) => opt?.org?.id !== undefined && opt?.org?.id === values.org.id,
    )
  }

  return options
})

export const filterSitesForDecode = memoize((values, options) => {
  if (values?.imei) {
    return options.filter((opt) => opt?.imei === values.imei)
  }

  if (values?.project?.id) {
    return options.filter(
      (opt) =>
        opt?.project?.id !== undefined &&
        opt?.project?.id === values.project.id,
    )
  }

  return options
})

// if site is storing ONLY the imei for any option, then we need to use the imei as the value
// if site is storing the logger serial for every logger entry, then we can use the logger serial as the value
export const remapLoggerOptions = memoize((values, options) => {
  const imeisPresent = values.use?.logger?.every((logger) => logger.imei)
  const serialsPresent = values.use?.logger?.every(
    (logger) => logger.loggerSerial,
  )

  if (imeisPresent && !serialsPresent) {
    return options.map((opt) => ({ ...opt, value: opt.imei }))
  }

  return options
})
