import type { QueryKey, QueryFunction, QueryOptions } from 'react-query'

import { useQuery } from 'hooks/useQuery'

interface useAsyncSelectOptionsProps {
  queryArgs: [QueryKey, QueryFunction, QueryOptions?]
  dataKey: string
  mapFn: (value: any, index: number, array: any[]) => any
  sortFn?: Function
  filterFn?: Function
}

type AsyncOptions<T> = {
  options: T[]
  isLoading: boolean
  isIdle?: boolean
  isSuccess?: boolean
  errors?: any
}

export const useAsyncSelectOptions = <T = unknown>({
  queryArgs,
  dataKey,
  mapFn,
  sortFn,
  filterFn,
}: useAsyncSelectOptionsProps): AsyncOptions<T> => {
  // FIXME: This is a hack to get around the fact that the return type of useQuery can be weird
  const { data, ...rest } = useQuery<any>(...queryArgs)

  let options = []

  if (dataKey === '' && Array.isArray(data)) {
    options = data?.map(mapFn) ?? []
  }
  else {
    options = data?.[dataKey]?.map(mapFn) ?? []
  }

  if (filterFn) options.filter(filterFn)

  if (sortFn) options.sort(sortFn)

  return {
    options,
    ...rest,
  }
}
