import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  Grid,
  GridItem,
  HStack,
  Spacer,
  VStack,
  chakra,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import {
  modemWithContextInitializer,
  ModemWithContextInitializer,
} from '@beaded/models'

import { modem } from 'api'

import { ModalContainer } from 'containers/ModalContainer'

import { DefaultInput } from 'forms/inputs/DefaultInput'
import { SelectInput } from 'forms/inputs/SelectInput'

import { logValidationErrors } from 'lib/logValidationErrors'
import { queryClient } from 'lib/react-query'

type CreateModemFormInput = ModemWithContextInitializer

export const CreateModem = () => {
  const methods = useForm<CreateModemFormInput>({
    resolver: zodResolver(modemWithContextInitializer),
  })

  const mutation = useMutation(
    async (data: CreateModemFormInput) => {
      const validationResult = modemWithContextInitializer.safeParse(data)

      if (validationResult.success === false) {
        logValidationErrors(validationResult.error)
        return
      }

      const result = await modem.create(validationResult.data)

      if (result?.error) throw result.error

      return result
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries('modem')
      },
    },
  )

  console.log(methods.formState.errors)

  const handler = (data: CreateModemFormInput) => {
    return mutation.mutateAsync(data)
  }

  return (
    <ModalContainer
      TriggerButton={({ onClick }) => (
        <Button onClick={onClick}>Create Modem</Button>
      )}
      modalProps={{ size: '2xl' }}
      header='Create Modem'
      footer={() => (
        <VStack justifyContent='right' align='right' width='100%'>
          <HStack>
            <Spacer />
            {mutation.isError && (
              <pre>
                <chakra.code color='red.400'>
                  {JSON.stringify(mutation.error, null, 2)}
                </chakra.code>
              </pre>
            )}
            {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
            {mutation.isError && <WarningIcon color='red.400' />}
            <Button
              onClick={methods.handleSubmit(handler)}
              isLoading={mutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      )}
      onClose={methods.reset}
    >
      <FormProvider {...methods}>
        <form>
          <Grid templateColumns='repeat(4, 1fr)' gap={3}>
            <GridItem colSpan={4}>
              <DefaultInput name='imei' label='IMEI' />
            </GridItem>

            <GridItem colSpan={2}>
              <SelectInput
                name='platform'
                label='Platform'
                options={['IRIDIUM']}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <SelectInput
                name='provider'
                label='Provider'
                options={['CLOUDLOOP']}
              />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </ModalContainer>
  )
}
