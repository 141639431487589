import type { QueryKey, QueryFunction } from 'react-query'

import { UserWithPermissions } from '@beaded/models'

import { user } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

export const useUsersList = () => {
  const { superUser } = useUserContext()

  const options: [QueryKey, QueryFunction] =
    superUser === 'on'
      ? [['users', { superUser }], () => user.admin.getAll()]
      : ['users', () => user.getAll()]

  const { data, ...rest } = useQuery<{ users: UserWithPermissions[] }>(
    ...options,
  )

  return {
    users: data?.users ?? [],
    ...rest,
  }
}
