import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query'

import { cable } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import type { Obj } from 'types/common'

export const useCablesList = (
  filter?: Obj,
  queryOpts: UseQueryOptions = {},
  remapData?: (data: any) => any,
  filterData?: (data: any) => any,
  bulkExport?: {
    toggleIncludedCable: Function
    bulkExportMode: string
  },
) => {
  const { superUser, global } = useUserContext()

  const options: [QueryKey, QueryFunction, UseQueryOptions] =
    superUser === 'on'
      ? [
        ['cables', filter, { superUser }],
        ({ queryKey }) => cable.admin.getAll({ params: queryKey[1] }),
        queryOpts,
      ]
      : [
        ['cables', filter],
        ({ queryKey }) => cable.getAll({ params: queryKey[1] }),
        queryOpts,
      ]

  const { data, ...rest } = useQuery<{ cables: any }>(...options)

  let cables = data?.cables ?? []

  if (!global.view) cables = cables.filter((c: any) => Boolean(c.serial))
  if (remapData) cables = cables.map(remapData)
  if (bulkExport)
    cables = cables.map((c: any) => ({
      ...c,
      _addCableForExport: bulkExport.toggleIncludedCable,
      _bulkExportMode   : bulkExport.bulkExportMode,
    }))
  if (filterData) cables = cables.filter(filterData)

  return {
    cables,
    ...rest,
  }
}
