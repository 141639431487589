import { HStack, Spacer, Text, SkeletonText } from '@chakra-ui/react'

import { ErrorCard } from 'components/ErrorCard'
import {
  ModemsTable,
  ModemTableProjection,
} from 'components/tables/ModemsTable.component'

import { Card } from 'containers/Card'

import { CreateModem } from 'forms/CreateModem'

import { useTableDownload } from 'hooks/useTableDownload'

import { getTableTitle } from 'lib/getTableTitle'

import type { PageCard } from 'types/PageCard'

import { useModemsList } from './useModemsList'

export type ModemsCardProps = PageCard<ModemTableProjection>

export const ModemsCard = ({
  type,
  input,
  globalFilter,
  global,
  initialValues,
  projection,
  filename = 'modems.csv',
}: ModemsCardProps) => {
  const { isLoading, errors, modems } = useModemsList(
    type === 'query' ? input : null,
    {
      enabled: type === 'query',
    },
  )
  const { download, DownloadButton } = useTableDownload(filename)

  const name = getTableTitle('Modem', type === 'query' ? modems : input)

  return (
    <>
      <Card>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>{name}</Text>
          <Spacer />
          {global.view && <DownloadButton />}
          {global.edit && <CreateModem />}
        </HStack>

        {isLoading ? (
          <SkeletonText noOfLines={4} spacing={4} />
        ) : errors ? (
          <ErrorCard errors={errors} />
        ) : (
          <ModemsTable
            data={type === 'query' ? modems : input}
            globalFilter={globalFilter}
            download={download}
            projection={projection}
          />
        )}
      </Card>
    </>
  )
}
