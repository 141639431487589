import merge from 'lodash.merge'
import { useEffect, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import type { QueryKey, QueryFunction, QueryOptions } from 'react-query'

import { project } from 'api'

import { alphabeticalByName } from 'forms/helpers/alphabeticalByField'
import { useAsyncSelectOptions } from 'forms/helpers/useAsyncSelectOptions'
import { SelectInput, SelectInputProps } from 'forms/inputs/SelectInput'

import { useUserContext } from 'hooks/useUserContext'

type UseProjectSelectOptionsProps<T = any> = {
  filterFn?: (value: T, index?: number, array?: T[]) => boolean
}

type ProjectOptions = {
  name: string
  value: number
  organizationId: number
}

export const useProjectSelectOptions = (
  props: UseProjectSelectOptionsProps<ProjectOptions>,
) => {
  const { superUser } = useUserContext()

  const queryArgs: [QueryKey, QueryFunction, QueryOptions?] =
    superUser === 'on'
      ? [
        ['project', { superUser, projection: 'menu' }],
        () => project.admin.getMenuProjection(),
      ]
      : [['project', { projection: 'menu' }], () => project.getMenuProjection()]

  const { options, ...rest } = useAsyncSelectOptions<ProjectOptions>({
    queryArgs,
    dataKey: 'proj',
    mapFn  : (project: any) => ({
      name          : project.projectName,
      value         : project.id,
      organizationId: project.organizationId,
      visible       : project.visible,
    }),
    filterFn: props.filterFn,
    sortFn  : alphabeticalByName,
  })

  return {
    options,
    ...rest,
  }
}

export const SelectProjectWithOrgFilter = ({
  watchField = 'organizationId',
  name,
  label,
  placeholder,
  hookformProps = {},
}: Omit<SelectInputProps, 'options'> & { watchField?: string }) => {
  const { watch, getValues } = useFormContext()
  const { options } = useProjectSelectOptions({
    filterFn: (p: any) => p.visible,
  })
  const organizationId = watch(watchField)
  const [opts, setOpts] = useState<any[]>([])

  useEffect(() => {
    if (organizationId)
      setOpts(
        options?.filter(
          (opt: any) =>
            opt.organizationId === Number(organizationId) && opt.visible,
        ),
      )
  }, [])

  useEffect(() => {
    const { unsubscribe } = watch((data, { name }) => {
      if (name === watchField) {
        const newValue = getValues(watchField)
        setOpts(
          options?.filter(
            (opt: any) =>
              opt.organizationId === Number(newValue) && opt.visible,
          ),
        )
      }
    })
    return () => unsubscribe()
  }, [getValues, options, organizationId, watch, watchField])

  return (
    <SelectInput
      name={name}
      label={label}
      options={opts}
      placeholder={placeholder ?? 'Select a project'}
      hookformProps={merge(hookformProps, {
        valueAsNumber: true,
        disabled     : !organizationId,
      })}
    />
  )
}

export const SelectProjectWithFilter = (
  props: Omit<SelectInputProps, 'options'> & {
    filterFn: (opt: any) => boolean
    watchField: string
  },
) => {
  const { watch } = useFormContext()
  const value = watch(props.watchField)
  const { options } = useProjectSelectOptions({})

  return (
    <SelectInput
      name={props.name}
      label={props.label}
      options={options.filter(props.filterFn)}
      placeholder={
        options.length === 0
          ? 'No projects found'
          : props.placeholder ?? 'Select a Project'
      }
      hookformProps={merge(props.hookformProps, {
        valueAsNumber: true,
        disabled     : !value || options.length === 0,
      })}
    />
  )
}
