import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'
import {
  loggerConditionType,
  type default as LoggerConditionType,
} from './LoggerConditionType.js'
import { loggerHistoryId, type LoggerHistoryId } from './LoggerHistory.js'
import { organizationId, type OrganizationId } from './Organization.js'
import { projectId, type ProjectId } from './Project.js'

/** Represents the view public.logger_history_view_ */
export default interface LoggerHistoryViewTable {
  /** Database type: pg_catalog.timestamptz */
  createdAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.uuid */
  createdBy: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.uuid */
  updatedBy: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.timestamptz */
  deletedAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.uuid */
  deletedBy: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.int4 */
  id: ColumnType<LoggerHistoryId, never, never>

  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, never, never>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date, never, never>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, never, never>

  /** Database type: public.logger_condition_type_ */
  condition: ColumnType<LoggerConditionType | null, never, never>

  /** Database type: pg_catalog.int4 */
  organizationId: ColumnType<OrganizationId, never, never>

  /** Database type: pg_catalog.int4 */
  projectId: ColumnType<ProjectId, never, never>

  /** Database type: pg_catalog.text */
  organizationName: ColumnType<string, never, never>

  /** Database type: pg_catalog.timestamptz */
  organizationDeletedAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.text */
  projectName: ColumnType<string, never, never>

  /** Database type: pg_catalog.timestamptz */
  projectDeletedAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.text */
  createdByUser: ColumnType<string, never, never>

  /** Database type: pg_catalog.text */
  updatedByUser: ColumnType<string, never, never>

  /** Database type: pg_catalog.text */
  deletedByUser: ColumnType<string, never, never>
}

export type LoggerHistoryView = Selectable<LoggerHistoryViewTable>

export const loggerHistoryView = z.object({
  createdAt            : z.coerce.date().nullable().optional(),
  createdBy            : z.string().nullable().optional(),
  updatedAt            : z.coerce.date().nullable().optional(),
  updatedBy            : z.string().nullable().optional(),
  deletedAt            : z.coerce.date().nullable().optional(),
  deletedBy            : z.string().nullable().optional(),
  id                   : loggerHistoryId,
  loggerId             : loggerId,
  startedAt            : z.coerce.date(),
  endedAt              : z.coerce.date().nullable().optional(),
  condition            : loggerConditionType.nullable().optional(),
  organizationId       : organizationId,
  projectId            : projectId,
  organizationName     : z.string(),
  organizationDeletedAt: z.coerce.date().nullable().optional(),
  projectName          : z.string(),
  projectDeletedAt     : z.coerce.date().nullable().optional(),
  createdByUser        : z.string(),
  updatedByUser        : z.string(),
  deletedByUser        : z.string(),
}) as unknown as z.Schema<LoggerHistoryView>
