import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query'

import { ModemWithContext } from '@beaded/models'

import { modem } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import type { Obj } from 'types/common'

export const useModemsList = (
  filter?: Obj,
  queryOpts: UseQueryOptions = {},
) => {
  const { superUser, global } = useUserContext()

  const options: [QueryKey, QueryFunction, UseQueryOptions] =
    superUser === 'on'
      ? [
        ['modems', filter, { superUser }],
        ({ queryKey }) => modem.admin.getAll({ params: queryKey[1] }),
        queryOpts,
      ]
      : [
        ['modems', filter],
        ({ queryKey }) => modem.getAll({ params: queryKey[1] }),
        queryOpts,
      ]

  const { data, ...rest } = useQuery<{ modems: ModemWithContext[] }>(...options)

  return {
    modems: data?.modems ?? [],
    ...rest,
  }
}
