interface ISerial {
  model: string
  id: number
}

export const formatLoggerSerial = (serial?: ISerial) => {
  if (!serial) return ''
  if (serial?.model === undefined || serial?.id === undefined) return ''

  switch (serial?.model) {
    case 'SL':
      return `${serial?.model}${serial?.id?.toString().padStart(5, '0')}`

    case 'DLB':
    case 'NGT':
      return `${serial?.model}${serial?.id?.toString().padStart(5, '0')}`

    case 'D405':
    case 'D505':
    case 'D605':
      return `${serial?.model}${serial?.id?.toString().padStart(4, '0')}`

    default:
      return `${serial?.model}${serial?.id?.toString().padStart(4, '0')}`
  }
}

export const parseLoggerSerial = (serial: string) => {
  let model, id
  if (
    serial.toUpperCase().startsWith('D405') ||
    serial.toUpperCase().startsWith('D505') ||
    serial.toUpperCase().startsWith('D605')
  ) {
    model = serial.slice(0, 4)
    id = parseInt(serial.slice(4), 10)
  }

  if (
    serial.toUpperCase().startsWith('DLB') ||
    serial.toUpperCase().startsWith('NGT')
  ) {
    model = serial.slice(0, 3)
    id = parseInt(serial.slice(3), 10)
  }

  if (serial.toUpperCase().startsWith('SL')) {
    model = serial.slice(0, 2)
    id = parseInt(serial.slice(2), 10)
  }

  return { model, id }
}
