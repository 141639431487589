import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import {
  siteLoggerHistoryId,
  type SiteLoggerHistoryId,
} from './SiteLoggerHistory.js'

/** Identifier type for public.site_logger_history_label_ */
export type SiteLoggerHistoryLabelId = number & {
  __brand: 'SiteLoggerHistoryLabelId'
}

/** Represents the table public.site_logger_history_label_ */
export default interface SiteLoggerHistoryLabelTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    SiteLoggerHistoryLabelId,
    SiteLoggerHistoryLabelId | undefined,
    SiteLoggerHistoryLabelId
  >

  /** Database type: pg_catalog.numeric */
  number: ColumnType<number | null, number | null, number | null>

  /** Database type: pg_catalog.text */
  note: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.bool */
  visible: ColumnType<boolean | null, boolean | null, boolean | null>

  /** Database type: pg_catalog.int4 */
  siteLoggerHistoryId: ColumnType<
    SiteLoggerHistoryId,
    SiteLoggerHistoryId,
    SiteLoggerHistoryId
  >
}

export type SiteLoggerHistoryLabel = Selectable<SiteLoggerHistoryLabelTable>

export type SiteLoggerHistoryLabelInitializer =
  Insertable<SiteLoggerHistoryLabelTable>

export type SiteLoggerHistoryLabelMutator =
  Updateable<SiteLoggerHistoryLabelTable>

export const siteLoggerHistoryLabelId =
  z.number() as unknown as z.Schema<SiteLoggerHistoryLabelId>

export const siteLoggerHistoryLabel = z.object({
  id                 : siteLoggerHistoryLabelId,
  number             : z.number().nullable().optional(),
  note               : z.string().nullable().optional(),
  visible            : z.boolean().nullable().optional(),
  siteLoggerHistoryId: siteLoggerHistoryId,
}) as unknown as z.Schema<SiteLoggerHistoryLabel>

export const siteLoggerHistoryLabelInitializer = z.object({
  id                 : siteLoggerHistoryLabelId.optional(),
  number             : z.number().optional().nullable().optional(),
  note               : z.string().optional().nullable().optional(),
  visible            : z.boolean().optional().nullable().optional(),
  siteLoggerHistoryId: siteLoggerHistoryId,
}) as unknown as z.Schema<SiteLoggerHistoryLabelInitializer>

export const siteLoggerHistoryLabelMutator = z.object({
  id                 : siteLoggerHistoryLabelId.optional(),
  number             : z.number().optional().nullable().optional(),
  note               : z.string().optional().nullable().optional(),
  visible            : z.boolean().optional().nullable().optional(),
  siteLoggerHistoryId: siteLoggerHistoryId.optional(),
}) as unknown as z.Schema<SiteLoggerHistoryLabelMutator>
