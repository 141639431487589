import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'

/** Represents the view public.logger_cloudloop_view_ */
export default interface LoggerCloudloopViewTable {
  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, never, never>

  /** Database type: pg_catalog.text */
  imei: ColumnType<string, never, never>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date, never, never>

  /** Database type: pg_catalog.text */
  hardwareId: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.jsonb */
  cacheData: ColumnType<unknown, never, never>

  /** Database type: pg_catalog.text */
  subscriberId: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.text */
  networkStatus: ColumnType<string | null, never, never>
}

export type LoggerCloudloopView = Selectable<LoggerCloudloopViewTable>

export const loggerCloudloopView = z.object({
  loggerId     : loggerId,
  imei         : z.string(),
  updatedAt    : z.coerce.date(),
  hardwareId   : z.string().nullable().optional(),
  cacheData    : z.unknown(),
  subscriberId : z.string().nullable().optional(),
  networkStatus: z.string().nullable().optional(),
}) as unknown as z.Schema<LoggerCloudloopView>
