import { BarcodeScanner, DetectedBarcode } from 'react-barcode-scanner'
import 'react-barcode-scanner/polyfill'
import { useFormContext } from 'react-hook-form'

export const shouldShowImeiStuff = (model: string) => {
  return model !== 'SL' && model !== 'NGT'
}

type ImeiScannerProps = {
  enableScanner: boolean
  setEnableScanner: (value: boolean) => void
  fieldName: string
  dateField: string
}
export const ImeiScanner = (props: ImeiScannerProps) => {
  const { setValue } = useFormContext()

  const onCapture = (barcodes: DetectedBarcode[]) => {
    for (const barcode of barcodes) {
      if (barcode.format === 'code_128' && barcode?.rawValue?.length === 15) {
        // set imei field
        setValue(props.fieldName, barcode.rawValue)
        setValue(props.dateField, new Date())
        props.setEnableScanner(false)
      }
    }
  }
  return (
    <>
      <BarcodeScanner
        options={{ formats: ['code_128'] }}
        onCapture={onCapture}
      />
    </>
  )
}
