import { Text, Tooltip, useBreakpointValue, VStack } from '@chakra-ui/react'
import { format as formatDate } from 'date-fns'
import memoize from 'memoizee'
import { Cell } from 'react-table'

import { LoggerWithContext } from '@beaded/models'

import { actions } from 'components/ActionsBlock'
import { InternalLink } from 'components/Links'
import { NotesModal } from 'components/NotesModal'
import { SparkLine } from 'components/SparkLine'
import { DataTable } from 'components/Table'

import { UpdateLogger } from 'forms/UpdateLogger'

import { DownloadColumn } from 'lib/getObjectKeys'
import { formatLoggerSerial } from 'lib/loggerSerial'
import { deleteLogger } from 'lib/mutations/delete/deleteLogger'
import { convertMacroCaseToEnglish } from 'lib/recase'

import type { KeysOf, Obj } from 'types/common'

type LoggerCell = Cell & {
  row: {
    original: LoggerWithContext & {
      _filter?: (logger: LoggerWithContext) => boolean
      _customProps?: (cell: any) => { [key: string]: any }
    }
  }
}

interface LoggersTableProps {
  data: any
  globalFilter: any
  projection: LoggersTableProjection
  download: any
  sortBy?: Array<any>
}

export const LoggersTable = ({
  data = [],
  globalFilter,
  projection = 'default',
  download,
  sortBy = [],
}: LoggersTableProps) => {
  const zoom = useBreakpointValue({
    base: 0.9,
    md  : 1,
  })

  return (
    <>
      {data && data?.length > 0 ? (
        <DataTable
          columns={projections[projection]}
          data={data}
          initialGlobalFilter={globalFilter}
          hiddenColumns={hiddenColumns}
          hideGlobalFilter={!(data.length > 10)}
          download={download}
          downloadColumns={downloadColumns}
          sortBy={sortBy}
          sx={{
            zoom,
            mozTransform: `scale(${zoom})`,
          }}
        />
      ) : (
        <Text>no data found</Text>
      )}
    </>
  )
}

const hiddenColumns: string[] = []
const downloadColumns: DownloadColumn[] = [
  'id',
  { column: 'loggerSerial', name: 'Serial' },
  { column: 'firmwareVersion', name: 'Firmware' },
  { column: 'history.0.organizationId', name: 'Organization ID' },
  { column: 'history.0.organizationName', name: 'Organization Name' },
  { column: 'history.0.projectId', name: 'Project ID' },
  { column: 'history.0.projectName', name: 'Project Name' },
  { column: 'history.0.startedAt', name: 'Added to project' },
  { column: 'currentModem.imei', name: 'IMEI' },
  { column: 'currentModem.networkStatus', name: 'Modem Status' },
  { column: 'lastTransmission.recievedAt', name: 'Last Transmission - Time' },
  { column: 'lastTransmission.battery', name: 'Last Transmission - Battery' },
  {
    column: 'lastTransmission.msgSize',
    name  : 'Last Transmission - Size',
  },
  {
    column: 'lastTransmission.errors85',
    name  : 'Last Transmission - err 85',
  },
  {
    column: 'lastTransmission.errors99',
    name  : 'Last Transmission - err 99',
  },
  {
    column: 'lastTransmission.errors125',
    name  : 'Last Transmission - err 125',
  },
  {
    name       : 'Ports',
    aggregation: (data: any) => {
      // check port1Enabled, port2Enabled, port3Enabled, port4Enabled, port5Enabled and get function and type for each
      const ports = []
      for (let i = 1; i <= 5; i++) {
        const port = data[`port${i}Enabled`]
        if (port) {
          ports.push(`${data[`port${i}Type`]} - ${data[`port${i}Function`]}`)
        }
      }

      // create a string that indicates how many of each type of port are enabled
      const portCounts = ports.reduce((acc: any, port) => {
        acc[port] = (acc[port] || 0) + 1
        return acc
      }, {})

      // return a string that lists the enabled ports and the number of each type
      return Object.entries(portCounts)
        .map(([port, count]) => `${count} ${port}`)
        .join(', ')
    },
  },
]

const columns = {
  id: {
    Header  : 'id',
    accessor: 'id',
  },

  firmware: {
    Header  : 'Firmware',
    accessor: 'firmwareVersion',
  },

  deviceType: {
    Header  : 'Type',
    accessor: 'deviceType',
  },

  notes: {
    Header   : 'Notes',
    accessor : 'notes',
    isNumeric: true,
    Cell     : ({ value, row }: LoggerCell) => {
      if (!value) return null

      return (
        <NotesModal
          note={value}
          name={formatLoggerSerial(row.values?.serial)}
        />
      )
    },
  },

  serial: {
    Header  : 'serial',
    accessor: 'loggerSerial',
    Cell    : ({ value, row }: LoggerCell) => (
      <InternalLink name={value} href={`/loggers/${row?.original?.id}`} />
    ),
  },

  serialNoLink: {
    Header  : 'serial',
    accessor: 'loggerSerial',
  },

  adminSerial: {
    Header  : 'serial',
    accessor: 'loggerSerial',
    Cell    : ({ value, row, ...rest }: LoggerCell) => {
      if (!shouldStrikeThrough(row?.original))
        return (
          <InternalLink name={value} href={`/loggers/${row?.original?.id}`} />
        )

      return (
        <Text color='red' textDecoration='line-through'>
          <InternalLink name={value} href={`/loggers/${row?.original?.id}`} />
        </Text>
      )
    },
  },

  batterySparkline: {
    Header  : 'Battery Trend',
    accessor: 'lastTransmission.batteryTrend',
    Cell    : ({ value }: LoggerCell) => {
      if (value && value.length > 0) return <SparkLine data={value} />
      return null
    },
  },

  ports: {
    id      : 'ports',
    Header  : 'Ports',
    accessor: memoize((originalRow: LoggerWithContext) => {
      const ports = []
      for (let i = 1; i <= 5; i++) {
        const port = originalRow[`port${i}Enabled` as keyof LoggerWithContext]
        if (port) {
          ports.push(
            `${originalRow[`port${i}Type` as keyof LoggerWithContext]} - ${originalRow[`port${i}Function` as keyof LoggerWithContext]}`,
          )
        }
      }

      const portCounts = ports.reduce((acc: any, port) => {
        acc[port] = (acc[port] || 0) + 1
        return acc
      }, {})

      return Object.entries(portCounts).map(
        ([port, count]) => `${count} ${port}`,
      )
    }),
    Cell: ({ value }: LoggerCell) => {
      // this should be an array of strings
      return (
        <>
          {value.map((port: string) => (
            <Text key={port}>{port}</Text>
          ))}
        </>
      )
    },
  },

  condition: {
    Header  : 'Condition',
    accessor: memoize((originalRow: LoggerWithContext) => {
      if (!originalRow?.history?.at(-1)?.condition) return null

      return convertMacroCaseToEnglish(
        originalRow?.history?.at(-1)?.condition as string,
      )
    }),
  },

  adminCondition: {
    Header  : 'Condition',
    accessor: memoize((originalRow: LoggerWithContext) => {
      return originalRow?.history?.at(-1)?.condition?.toLowerCase()
    }),
    Cell: ({ value, row }: LoggerCell) => {
      if (
        typeof row?.original?._filter === 'function' &&
        row.original._filter(row?.original)
      )
        return <Text>{value}</Text>

      return null
    },
  },

  imei: {
    Header  : 'IMEI',
    accessor: 'currentModem.imei',
  },

  imeiNoLink: {
    Header  : 'IMEI',
    accessor: 'currentModem.imei',
  },

  adminOrg: {
    Header  : 'Org',
    accessor: memoize(
      (originalRow: LoggerWithContext) =>
        originalRow?.history?.at(-1)?.organizationName,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      if (value)
        return (
          <InternalLink
            name={value}
            href={`/orgs/${row?.original?.history?.at(-1)?.organizationId}`}
          />
        )

      return null
    },
  },

  org: {
    Header  : 'Org',
    accessor: memoize(
      (originalRow: LoggerWithContext) =>
        originalRow?.history?.at(-1)?.organizationName,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      if (value)
        return (
          <InternalLink
            name={value}
            href={`/orgs/${row?.original?.history?.at(-1)?.organizationId}`}
          />
        )

      return null
    },
  },

  project: {
    Header  : 'Project',
    accessor: memoize(
      (originalRow: LoggerWithContext) =>
        originalRow?.history?.at(-1)?.projectName,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      if (value)
        return (
          <InternalLink
            name={value}
            href={`/projects/${row?.original?.history?.at(-1)?.projectId}`}
          />
        )

      return null
    },
  },

  adminProject: {
    Header  : 'Project',
    accessor: memoize(
      (originalRow: LoggerWithContext) =>
        originalRow?.history?.at(-1)?.projectName,
    ),
    Cell: ({ value, row }: LoggerCell) => {
      return (
        <InternalLink
          name={value}
          href={`/projects/${row?.original?.history?.at(-1)?.projectId}`}
        />
      )
    },
  },

  modemStatus: {
    id    : 'modemStatus',
    Header: () => (
      <Tooltip label='Modem status reported by cloudloop'>Modem Status</Tooltip>
    ),
    accessor: memoize((originalRow: LoggerWithContext) => {
      return originalRow?.currentModem?.networkStatus
    }),
    Cell: ({ value }: LoggerCell) => {
      // red for suspended, green for activated, orange for anything else
      return (
        <Text
          color={
            value === 'ACTIVATED'
              ? 'green'
              : value === 'SUSPENDED'
                ? 'red'
                : 'orange'
          }
        >
          {value}
        </Text>
      )
    },
  },

  lastLogged: {
    Header  : 'Last Transmission',
    accessor: 'lastTransmission.recievedAt',
    Cell    : ({ value }: LoggerCell) =>
      value !== undefined && value !== 0 && value !== null
        ? formatDate(new Date(value), 'yyyy-MM-dd')
        : null,
  },

  lastVoltage: {
    Header  : 'Last Voltage',
    accessor: 'lastTransmission.battery',
    Cell    : ({ value }: LoggerCell) => (
      <Text
        as='span'
        color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
      >
        {value ? `${value.toFixed(2)} V` : null}
      </Text>
    ),
  },

  lastTransmission: {
    Header    : 'Last Transmission',
    isCentered: true,
    columns   : [
      {
        Header  : 'Time',
        accessor: 'lastTransmission.recievedAt',
        Cell    : ({ value, row }: LoggerCell) => (
          <Text
            {...(row.values.status === 'retired'
              ? { color: 'gray.500', fontStyle: 'italic' }
              : {})}
          >
            {value !== undefined && value !== 0 && value !== null
              ? formatDate(new Date(value), 'yyyy-MM-dd')
              : null}
          </Text>
        ),
      },
      {
        Header  : 'Voltage',
        accessor: 'lastTransmission.battery',
        Cell    : ({ value }: LoggerCell) => (
          <Text
            as='span'
            color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
          >
            {value ? `${value.toFixed(2)} V` : null}
          </Text>
        ),
      },
    ],
  },

  adminLastTransmission: {
    Header    : 'Last Transmission',
    isCentered: true,
    columns   : [
      {
        id      : 'adminLastTransmissionTime table-col-gray',
        Header  : 'Time',
        accessor: 'lastTransmission.recievedAt',
        Cell    : ({ value, row }: LoggerCell) => {
          row.original._customProps = _customProps

          if (shouldStrikeThrough(row?.original))
            return (
              <Text>
                This logger is currently assigned to{' '}
                <Text as='span' fontWeight='600'>
                  <InternalLink
                    name={row?.original?.history?.at(-1)?.projectName as string}
                    href={`/conditions/${row?.original?.history?.at(-1)?.projectId}`}
                  />
                </Text>{' '}
                under{' '}
                <Text as='span' fontWeight='600'>
                  <InternalLink
                    name={
                      row?.original?.history?.at(-1)?.organizationName as string
                    }
                    href={`/orgs/${row?.original?.history?.at(-1)?.organizationId}`}
                  />
                </Text>
              </Text>
            )

          return (
            <Text
              {...(row.values.status === 'retired'
                ? { color: 'gray.500', fontStyle: 'italic' }
                : {})}
            >
              {value !== undefined && value !== 0 && value !== null
                ? formatDate(new Date(value), 'yyyy-MM-dd')
                : null}
            </Text>
          )
        },
      },
      {
        id      : 'adminLastTransmissionVoltage table-col-gray',
        Header  : 'Voltage',
        accessor: 'lastTransmission.battery',
        Cell    : ({ value, row }: LoggerCell) => {
          row.original._customProps = _customProps

          return (
            <Text
              as='span'
              color={value < 5.9 ? 'red' : value < 6 ? 'orange' : 'green'}
            >
              {value ? `${value.toFixed(2)} V` : null}
            </Text>
          )
        },
      },
      {
        id      : 'adminLastTransmissionBytes table-col-gray',
        Header  : 'Bytes',
        accessor: memoize(
          (originalRow: LoggerWithContext) =>
            originalRow?.lastTransmission?.msgSize,
        ),
        Cell: ({ value, row }: LoggerCell) => {
          row.original._customProps = _customProps

          return (
            <Text as='span' color={value ? (value <= 9 ? 'red' : 'green') : ''}>
              {value && value > 0 ? `${value} bytes` : null}
            </Text>
          )
        },
      },
      {
        id      : 'adminLastTransmissionErrors table-col-gray',
        Header  : 'Errors',
        accessor: 'lastTransmssion',
        Cell    : ({ row, value }: LoggerCell) => {
          row.original._customProps = _customProps

          const res = []
          if (value) {
            if (value.errors85 > 0)
              res.push(
                <Text key={'85'} color='red'>
                  85: {value.errors85}
                </Text>,
              )
            if (value.errors99 > 0)
              res.push(
                <Text key={'99'} color='red'>
                  99: {value.errors99}
                </Text>,
              )
            if (value.errors125 > 0)
              res.push(
                <Text key={'127'} color='red'>
                  125: {value.errors125}
                </Text>,
              )
          }

          return <VStack>{res}</VStack>
        },
      },
      {
        id      : 'adminLastTransmissionbatteryTrend table-col-gray',
        Header  : 'Battery Trend',
        accessor: 'lastTransmission.batteryTrend',
        Cell    : ({ value }: LoggerCell) => {
          if (value && value.length > 0) return <SparkLine data={value} />
          return null
        },
      },
    ],
  },

  actions: actions({
    getName        : (logger: LoggerWithContext) => logger?.loggerSerial,
    CustomFormModal: UpdateLogger,
    deleteFn       : deleteLogger,
    changelog      : true,
  }),
}

const projections = {
  default: [
    columns.id,
    columns.serial,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.firmware,
    columns.ports,
    columns.imei,
    columns.project,
    columns.org,
  ],

  orgPage: [
    columns.serialNoLink,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.project,
  ],

  adminOrgPage: [
    columns.id,
    columns.serial,
    columns.adminLastTransmission,
    columns.firmware,
    columns.condition,
    columns.imei,
    columns.modemStatus,
    columns.ports,
    columns.adminOrg,
    columns.adminProject,
    columns.notes,
    columns.actions,
  ],

  projectPage: [
    columns.serialNoLink,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.firmware,
    columns.ports,
  ],

  adminProjectPage: [
    columns.id,
    columns.adminSerial,
    columns.adminLastTransmission,
    columns.firmware,
    columns.adminCondition,
    columns.imei,
    columns.modemStatus,
    columns.ports,
    columns.notes,
    columns.actions,
  ],

  globalPage: [
    columns.serialNoLink,
    columns.lastTransmission,
    columns.batterySparkline,
    columns.modemStatus,
    columns.firmware,
    columns.ports,
    columns.project,
    columns.org,
  ],

  adminGlobalPage: [
    columns.id,
    columns.serial,
    columns.adminLastTransmission,
    columns.firmware,
    columns.ports,
    columns.condition,
    columns.imei,
    columns.modemStatus,
    columns.adminOrg,
    columns.adminProject,
    columns.notes,
    columns.actions,
  ],
}

export type LoggersTableProjection = KeysOf<typeof projections>

const shouldStrikeThrough = (loggerRow: LoggerCell['row']['original']) =>
  !(typeof loggerRow?._filter === 'function' && loggerRow._filter(loggerRow)) &&
  window.location.pathname !== '/loggers'

const _customProps = (cell: any) => {
  const props: Obj = {}

  if (shouldStrikeThrough(cell.row.original)) {
    if (
      cell.column.id.startsWith('adminLastTransmissionBytes') ||
      cell.column.id.startsWith('adminLastTransmissionErrors') ||
      cell.column.id.startsWith('adminLastTransmissionVoltage')
    ) {
      props.style = {
        display: 'none',
      }
      props.colSpan = 1
    }

    if (cell.column.id.startsWith('adminLastTransmissionTime')) {
      props.colSpan = 4
    }
  }

  if (cell.column.id.startsWith('ports')) {
    // don't wrap the ports
    props.style = {
      whiteSpace: 'nowrap',
    }
  }

  return props
}
