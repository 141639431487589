import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query'

import { site } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import { sortSitesByAlphaWithDisplayOrder } from 'lib/sortSitesByAlphaWithDisplayOrder'

import type { Obj } from 'types/common'

export const useSitesList = (
  filter?: Obj,
  queryOpts: UseQueryOptions = {},
  remapData?: (data: any) => any,
  filterData?: (data: any) => any,
) => {
  const { superUser } = useUserContext()

  const options: [QueryKey, QueryFunction, UseQueryOptions] =
    superUser === 'on'
      ? [
        ['sites', filter, { superUser }],
        ({ queryKey }) => site.admin.getAll({ params: queryKey[1] }),
        queryOpts,
      ]
      : [
        ['sites', filter],
        ({ queryKey }) => site.getAll({ params: queryKey[1] }),
        queryOpts,
      ]

  const { data, ...rest } = useQuery<{ sites: any }>(...options)

  let sites = data?.sites?.sort(sortSitesByAlphaWithDisplayOrder) ?? []

  if (remapData) sites = sites.map(remapData)
  if (filterData) sites = sites.filter(filterData)

  return {
    sites,
    ...rest,
  }
}
