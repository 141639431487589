import { AnyZodObject, z } from 'zod'

import {
  ModemInitializer,
  ModemHistory,
  ModemHistoryInitializer,
  Modem,
  modemInitializer,
  modemHistoryInitializer,
  modemHistory,
  modem,
  ModemHistoryId,
  ModemHistoryMutator,
  modemHistoryMutator,
  modemMutator,
  ModemMutator,
  ModemId,
} from '../../index.js'

export type ModemWithContextInitializer = ModemInitializer & {
  history: Omit<ModemHistoryInitializer, 'id' | 'modemId'>[]
}

type OmmittedKeys =
  | 'modemId'
  | 'createdAt'
  | 'createdBy'
  | 'updatedAt'
  | 'updatedBy'
  | 'deletedAt'
  | 'deletedBy'

export type EmbeddedModemHistory = Omit<ModemHistory, OmmittedKeys> & {
  loggerSerial: string
}

export type ModemFilter = {
  modemId?: ModemId
  imei?: string
}

export type MessagesPerTimeRange = {
  messages: number
  useId?: ModemHistoryId
  range: {
    inferredStart?: Date
    startedAt?: Date
    endedAt?: Date
    inferredEnd?: Date
  }
}

export type ModemWithContext = Modem & {
  history: EmbeddedModemHistory[]
  networkStatus: string
  extras?: {
    messagesPerTimeRange?: MessagesPerTimeRange[]
  }
}

export const modemWithContextInitializer = z
  .object({
    history: z.array(modemHistoryInitializer).optional(),
  })
  .merge(
    modemInitializer as unknown as AnyZodObject,
  ) as unknown as z.Schema<ModemWithContextInitializer>

export const modemWithContext = z
  .object({
    history: z.array(
      z
        .object({
          loggerSerial: z.string(),
        })
        .merge(modemHistory as unknown as AnyZodObject),
    ),
    networkStatus: z.string(),
  })
  .merge(
    modem as unknown as AnyZodObject,
  ) as unknown as z.Schema<ModemWithContext>

export type ModemWithContextMutator = ModemMutator & {
  history?: ModemHistoryMutator[]
}

export const modemWithContextMutator = z
  .object({
    history: z.array(modemHistoryMutator).optional(),
  })
  .merge(
    modemMutator as unknown as AnyZodObject,
  ) as unknown as z.Schema<ModemWithContextMutator>
