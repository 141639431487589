import { Text } from '@chakra-ui/react'
import fastJsonPatch from 'fast-json-patch'
import type { FormikValues } from 'formik'
import merge from 'lodash.merge'

import { site } from 'api'

import { GenerateUUID } from 'components/GenerateUUID/GenerateUUID'

import {
  useCableSelectOptions,
  filterLoggersByProjectId,
  filterCablesByProjectId,
  useProjectSelectOptions,
  filterProjectsByOrgId,
  filterCablesByOrgId,
  useLoggerMultiValueSelectOptions,
} from 'hooks/useSelectOptions'

import { siteSchema } from 'lib/jsValidate'
import { logValidationErrors } from 'lib/logValidationErrors'
import { combineDecodeList, parseDecodeOrder } from 'lib/parseDecodeOrder'
import { queryClient } from 'lib/react-query'
import { setDemoSensorLabel } from 'lib/setDemoSensorLabel'
import { validateDate } from 'lib/validateDate'

import type { FormInfo, UpdateFormProps } from 'types/FormInfo'
import type { ValidationError } from 'types/ValidationError'

const ignoreKeys = ['/extras', '/perm', '/mongoExtras', '/_id', '/ts']

export const updateSiteForm = ({
  initialValues,
}: UpdateFormProps): FormInfo<FormikValues> => ({
  fields: [
    {
      name       : 'name',
      type       : 'text',
      label      : 'Site Name',
      placeholder: 'Site Name',
      isRequired : true,
      validate   : (value: any) => {
        if (!value) return 'Site name is required'
      },
    },

    {
      name       : 'details',
      label      : 'Details (customer-facing)',
      type       : 'textarea',
      placeholder: 'Enter a description or details here...',
    },

    {
      name       : 'notes',
      label      : 'Notes (internal)',
      type       : 'textarea',
      placeholder: 'Enter notes here...',
    },

    {
      title       : 'Location',
      titleColSpan: 2,
      type        : 'group',
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          name : 'geo.lat',
          type : 'text',
          label: 'Latitude',
        },
        {
          name : 'geo.lon',
          type : 'text',
          label: 'Longitude',
        },
      ],
    },

    {
      title       : 'Snow Depth',
      titleColSpan: 2,
      type        : 'group',
      gridProps   : {
        templateColumns: '1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          name : 'sensor.height',
          type : 'number',
          label: 'Sonic Sensor Height (cm)',
        },
        {
          name         : 'display.plot_snow',
          type         : 'checkbox',
          label        : 'Plot Snow',
          gridItemProps: {
            justifySelf: 'center',
            alignSelf  : 'center',
          },
        },
      ],
    },

    {
      title       : 'Airtemp',
      titleColSpan: 3,
      type        : 'group',
      gridProps   : {
        templateColumns: '1fr 1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          name         : 'display.plot_airtemp',
          type         : 'checkbox',
          label        : 'Plot Air Temp',
          gridItemProps: {
            justifySelf: 'center',
            alignSelf  : 'center',
            marginTop  : 8,
          },
        },
        {
          name            : 'cable.airtemp',
          placeholder     : 'select airtemp cable',
          type            : 'select',
          label           : 'Air Temp Serial',
          useSelectOptions: useCableSelectOptions,
          filter          : filterCablesByOrgId,
          keyPrefix       : 'airtemp-serial-',
        },
        {
          // Decode order for airtemp cable
          name : 'sensor.at',
          type : 'number',
          label: 'Airtemp Decode',
        },
      ],
    },

    {
      type        : 'group',
      title       : 'Label Settings',
      titleColSpan: 3,
      gridProps   : {
        templateColumns: '1fr 1fr 1fr',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          label  : 'Units',
          name   : 'data.units.length',
          type   : 'radio',
          options: [
            { value: 'in', name: 'in' },
            { value: 'ft', name: 'ft' },
            { value: 'm', name: 'm' },
            { value: 'cm', name: 'cm' },
          ],
          onChange     : setDemoSensorLabel,
          gridItemProps: {
            paddingBottom: 3,
          },
        },
        {
          label  : 'Sort Order',
          name   : 'labelSettings.sortBy',
          type   : 'radio',
          options: [
            { value: 'serial', name: 'Cable' },
            { value: 'depth', name: 'Depth' },
          ],
          tooltip: 'Sort labels by serial number or depth',
        },
        {
          label        : 'Show Serials',
          name         : 'labelSettings.showCableSerials',
          type         : 'checkbox',
          gridItemProps: {
            paddingTop: '32px',
          },
          tooltip: 'Show cable serials in line labels',
        },
      ],
    },

    {
      title      : 'Logger History',
      type       : 'array',
      name       : 'use.logger',
      description: (
        <Text fontWeight='500'>
          History must be in chronological order, with the oldest entry at the
          top.
        </Text>
      ),
      newItem: () => ({
        imei : '',
        begin: '',
      }),
      newItemText: 'Add Logger',
      removeItem : true,
      gridProps  : {
        templateColumns: '1fr 1fr 1fr 1fr auto',
        gap            : 3,
      },
      headers: ['Logger', 'Begin Date', 'End Date', 'Decode', ''],
      fields : [
        {
          name:
            initialValues?.use?.logger?.every((l: any) => l?.imei) &&
            !initialValues?.use?.logger?.every((l: any) => l?.loggerSerial)
              ? 'imei'
              : 'loggerSerial',
          type               : 'select',
          label              : '',
          placeholder        : 'Select Logger',
          useSelectOptions   : useLoggerMultiValueSelectOptions,
          filter             : filterLoggersByProjectId,
          useValueIfNoOptions: true,
          keyPrefix          : 'logger-raw-',
          onChange           : (e: any, setFieldValue: Function, values: FormikValues) => {
            // const value = e?.target?.value ?? e
            const name = e?.target?.name ?? 'imei'

            try {
              // get the contents of the `data-extras` key on the option and parse it from json
              const values = JSON.parse(
                e.target.options[e.target.selectedIndex]?.dataset?.extras,
              )

              const baseName = name.split('.').slice(0, -1).join('.')

              const loggerIdFieldName = `${baseName}.id`
              const loggerSerialFieldName = `${baseName}.loggerSerial`
              const loggerImeiFieldName = `${baseName}.imei`

              console.log(
                values,
                loggerIdFieldName,
                loggerSerialFieldName,
                loggerImeiFieldName,
              )
              setFieldValue(loggerIdFieldName, values.id)
              setFieldValue(loggerSerialFieldName, values.loggerSerial)

              if (values.imei) setFieldValue(loggerImeiFieldName, values.imei)
            }
            catch (e) {
              console.error(e)
            }
            // try {
            //   const serial = e.target[e.target.selectedIndex].innerText
            //   const serialFieldName = name.replace('imei', 'loggerSerial')

            //   setFieldValue(serialFieldName, serial.split(' ')[0])
            // }
            // catch (error) {
            //   console.error(error)
            // }

            // setFieldValue(name, value)
          },
          style: {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
        },
        {
          name       : 'begin',
          type       : 'mask',
          label      : '',
          validate   : validateDate,
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
        },
        {
          name       : 'end',
          type       : 'mask',
          label      : '',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          validate   : validateDate,
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
        },
        {
          name           : 'decode',
          type           : 'text',
          label          : '',
          placeholder    : 'decode order',
          format         : combineDecodeList,
          shouldBeVisible: (values: any) => {
            return values?.readingsVersion === 1
          },
        },
        {
          title          : 'Logger Labels (optional)',
          shouldBeVisible: (values: any) => {
            return values?.readingsVersion === 1
          },
          description: (
            <>
              <Text>
                Set labels for the logger by adding numbers and/or text. Use the
                'Visible' checkbox to hide labels from the chart. If both logger
                and cable labels are provided, logger labels take precedence.
              </Text>
              <Text fontWeight='semibold'>
                Logger oriented labeling is meant to replicate the functionality
                of previous labels and should not be used going forward
              </Text>
            </>
          ),
          type     : 'array',
          keyPrefix: 'logger-labels-',
          name     : 'labels',
          newItem  : () => ({ number: '', note: '', visible: true }),
          gridProps: {
            templateColumns: 'auto 1fr 1fr auto auto auto',
            gap            : 3,
          },
          newItemText      : 'Add Logger Label',
          duplicateItemText: 'Duplicate Logger Label',
          gridItemProps    : {
            colSpan     : 4,
            marginBottom: 3,
            border      : '1px solid rgb(226, 232, 240)',
            borderRadius: 'md',
          },
          showLineNumbers : true,
          lineNumberLabels: {
            text    : 'Label',
            position: 'left',
          },
          collapsible       : true,
          initiallyCollapsed: true,
          headers           : [
            '',
            'Depth',
            'Note (optional)',
            'Visible',
            'Line Label',
            '',
          ],
          fields: [
            {
              name       : 'number',
              type       : 'number',
              label      : '',
              placeholder: 'Sensor Depth',
              onChange   : setDemoSensorLabel,
              style      : {
                paddingInlineStart: 1,
                paddingInlineEnd  : 1,
              },
            },
            {
              name       : 'note',
              type       : 'text',
              label      : '',
              placeholder: 'Sensor Note',
              onChange   : setDemoSensorLabel,
              style      : {
                paddingInlineStart: 1,
                paddingInlineEnd  : 1,
              },
            },
            {
              name         : 'visible',
              type         : 'checkbox',
              label        : '',
              gridItemProps: {
                justifySelf: 'center',
                alignSelf  : 'center',
              },
              onChange: setDemoSensorLabel,
            },
            {
              name       : 'render',
              type       : 'label',
              label      : '',
              placeholder: '',
            },
          ],
        },
      ],
    },

    {
      title      : 'Cable History',
      type       : 'array',
      name       : 'use.cable',
      description: (
        <Text fontWeight='500'>
          History must be in chronological order, with the oldest entry at the
          top.
        </Text>
      ),
      newItem: () => ({
        serial: 0,
        begin : '',
      }),
      newItemText: 'Add Cable',
      removeItem : true,
      gridProps  : {
        templateColumns: '1fr 1fr 1fr 1fr auto',
        gap            : 3,
      },
      headers: ['Cable', 'Begin Date', 'End Date', 'Decode', ''],
      fields : [
        {
          name               : 'serial',
          type               : 'select',
          label              : '',
          placeholder        : 'Select Cable',
          useSelectOptions   : useCableSelectOptions,
          filter             : filterCablesByProjectId,
          keyPrefix          : 'cable-serial-',
          useValueIfNoOptions: true,
        },
        {
          name       : 'begin',
          type       : 'mask',
          label      : '',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          validate: validateDate,
        },
        {
          name       : 'end',
          type       : 'mask',
          label      : '',
          placeholder: 'YYYY-MM-DD HH:MM',
          mask       : '####-##-## ##:##',
          style      : {
            paddingInlineStart: 1,
            paddingInlineEnd  : 1,
          },
          validate: validateDate,
        },
        {
          name       : 'decode',
          type       : 'text',
          label      : '',
          placeholder: 'decode order',
          format     : combineDecodeList,
        },
        {
          title      : 'Cable Labels (optional)',
          description: (
            <>
              <Text>
                Set labels for the cable by adding numbers and/or text. Use the
                'Visible' checkbox to hide labels from the chart. If both logger
                and cable labels are provided, logger labels take precedence.
              </Text>
              <Text>
                Cable labels support 'fenceposting' for sensor depth, where
                entering the depth of some sensors allows automatic calculation
                of depths for the rest based on cable spacing data.
              </Text>
            </>
          ),
          type     : 'array',
          keyPrefix: 'cable-labels-',
          name     : 'labels',
          newItem  : () => ({ number: '', note: '', visible: true }),
          gridProps: {
            templateColumns: 'auto 1fr 1fr auto auto auto',
            gap            : 3,
          },
          newItemText      : 'Add Cable Label',
          duplicateItemText: 'Duplicate Cable Label',
          gridItemProps    : {
            colSpan     : 4,
            marginBottom: 3,
            border      : '1px solid rgb(226, 232, 240)',
            borderRadius: 'md',
          },
          showLineNumbers : true,
          lineNumberLabels: {
            text    : 'Label',
            position: 'left',
          },
          collapsible       : true,
          initiallyCollapsed: true,
          headers           : [
            '',
            'Depth',
            'Note (optional)',
            'Visible',
            'Line Label',
            '',
          ],
          fields: [
            {
              name       : 'number',
              type       : 'number',
              label      : '',
              placeholder: 'Sensor Depth',
              onChange   : setDemoSensorLabel,
              style      : {
                paddingInlineStart: 1,
                paddingInlineEnd  : 1,
              },
            },
            {
              name       : 'note',
              type       : 'text',
              label      : '',
              placeholder: 'Sensor Note',
              onChange   : setDemoSensorLabel,
              style      : {
                paddingInlineStart: 1,
                paddingInlineEnd  : 1,
              },
            },
            {
              name         : 'visible',
              type         : 'checkbox',
              label        : '',
              gridItemProps: {
                justifySelf: 'center',
                alignSelf  : 'center',
              },
              onChange: setDemoSensorLabel,
            },
            {
              name       : 'render',
              type       : 'label',
              label      : '',
              placeholder: '',
            },
          ],
        },
      ],
    },

    {
      type              : 'group',
      title             : 'Advanced Settings',
      collapsible       : true,
      initiallyCollapsed: true,
      gridItemProps     : {
        borderRadius: 'md',
        border      : '1px solid rgb(226, 232, 240)',
      },
      fields: [
        {
          type : 'number',
          label: 'Line Gap Size (hours)',
          description:
            'Set gap size in hours between points on the chart. Points further apart than this value will not be connected by a line. Default setting is 25 hours.',
          name: 'lineGapSize',
        },
      ],
    },

    {
      type        : 'group',
      title       : 'Admin Settings',
      titleColSpan: 6,
      gridProps   : {
        templateColumns: 'repeat(6, 1fr)',
        gap            : 3,
      },
      gridItemProps: {
        border      : '1px solid rgb(226, 232, 240)',
        borderRadius: 'md',
        padding     : 3,
      },
      fields: [
        {
          name            : 'project.id',
          type            : 'select',
          label           : 'Project',
          placeholder     : 'Select Project...',
          useSelectOptions: useProjectSelectOptions,
          filter          : filterProjectsByOrgId,
          isRequired      : true,
          gridItemProps   : {
            colSpan: 2,
          },
        },
        {
          name       : 'status',
          placeholder: 'Select status...',
          type       : 'select',
          label      : 'Status',
          options    : [
            { value: 'retired', name: 'Retired' },
            { value: 'active', name: 'Active' },
          ],
          isRequired   : true,
          gridItemProps: {
            colSpan: 2,
          },
        },
        {
          name       : 'readingsVersion',
          placeholder: 'Select readings version...',
          type       : 'select',
          label      : 'Readings Version',
          options    : [
            { value: 1, name: '1' },
            { value: 2, name: '2' },
          ],
          isRequired: true,
          tooltip:
            'This will be used to gracefully migrate sites to the new decode system. New sites should use version 2.',
          gridItemProps: {
            colSpan: 2,
          },
        },
        {
          name         : 'display.order',
          type         : 'number',
          label        : 'Display Order',
          gridItemProps: {
            colSpan: 2,
          },
        },
        {
          name         : 'visible',
          type         : 'checkbox',
          label        : 'Visible',
          tooltip      : 'If checked, the project will be visible to users.',
          gridItemProps: {
            paddingTop: '36px',
            colSpan   : 2,
          },
        },
        {
          name : 'includes_archived_data',
          type : 'checkbox',
          label: 'Includes Archived Data',
          tooltip:
            'If checked, the site includes archived data. Data can be archived on a case-by-case basis to improve performance.',
          gridItemProps: {
            paddingTop: '36px',
            colSpan   : 2,
          },
        },
        {
          name : 'useRecordDate',
          type : 'checkbox',
          label: 'Use Record Date',
          tooltip:
            'If checked, the site will use the logger reported record date instead of email date',
          gridItemProps: {
            paddingTop: '36px',
            colSpan   : 2,
          },
        },
        {
          name : 'showSdmLines',
          type : 'checkbox',
          label: 'Show SDM Data',
          tooltip:
            'If checked, the site will show the lines used to generate the SDM snow depth',
          gridItemProps: {
            paddingTop: '36px',
            colSpan   : 2,
          },
        },
        {
          type : 'number',
          label: 'Line Gap Size (hours)',
          description:
            'Set maximum gap size in hours between points on the chart. Points further apart than this value will not be connected by a line. The default setting is 25 hours.',
          name         : 'lineGapSize',
          gridItemProps: {
            colSpan: 6,
          },
        },
        {
          name         : 'web.uuid',
          label        : 'Chart Key',
          type         : 'text',
          isReadOnly   : false,
          right        : <GenerateUUID />,
          gridItemProps: {
            colSpan: 6,
          },
        },
      ],
    },
  ],

  title: 'Edit Site',

  modalProps: {
    size: '3xl',
  },

  initialValues: merge(
    {
      name                  : '',
      status                : 'active',
      includes_archived_data: false,
      project               : {
        id: 0,
      },
      data: {
        units: {
          length: 'm',
        },
      },
      visible: true,
      display: {
        order       : 0,
        plot_snow   : false,
        plot_airtemp: false,
      },
      cable: {
        airtemp: 0,
      },
      sensor: {
        at    : 0,
        height: 0,
      },
      geo: {
        lat      : 0,
        lon      : 0,
        elevation: 0,
      },
      details: '',
      use    : {
        logger: [{ imei: '' }],
        cable : [{ serial: 0 }],
      },
      labelSettings: {
        sortBy          : 'depth',
        showCableSerials: false,
      },
      readingsVersion: 1,
    },
    initialValues,
  ),

  submitText: 'Save Site',

  submitFn: async (values: any) => {
    if (Array.isArray(values?.data?.labels) && values.data.labels.length === 0)
      delete values.data.labels

    for (const l of values?.use?.logger ?? []) {
      if (l?.begin) {
        if (l.begin === '____-__-__' || l.begin === '____-__-__ __:__')
          delete l.begin
        else l.begin = new Date(l?.begin).toISOString()
      }
      if (l?.end) {
        if (l.end === '____-__-__' || l.end === '____-__-__ __:__') delete l.end
        else l.end = new Date(l?.end).toISOString()
      }
      if (Array.isArray(l?.decode) && l.decode.length === 0) delete l.decode
      if (l?.decode) {
        l.decode = parseDecodeOrder(l?.decode)
      }
    }

    // values.use.cable = values?.use?.cable?.filter((c: any) => c?.serial) ?? [];

    for (const c of values?.use?.cable ?? []) {
      if (c?.begin) {
        if (c.begin === '____-__-__' || c.begin === '____-__-__ __:__')
          delete c.begin
        else c.begin = new Date(c?.begin).toISOString()
      }
      if (c?.end) {
        if (c.end === '____-__-__' || c.end === '____-__-__ __:__') delete c.end
        else c.end = new Date(c?.end).toISOString()
      }
      if (Array.isArray(c?.decode) && c.decode.length === 0) delete c.decode
      if (c?.decode) {
        c.decode = parseDecodeOrder(c?.decode)
      }
    }

    const { value } = siteSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    const changes = fastJsonPatch
      .compare(initialValues as Object, value)
      .filter(
        (change) =>
          !(change.op === 'remove' && ignoreKeys.includes(change.path)),
      )

    const res = await site.patch(value?.id, changes)
    if (res.updated !== true) throw res

    return res
  },

  validateForm: (
    values: FormikValues,
  ): { error?: ValidationError; value: FormikValues } => {
    for (const l of values?.use?.logger ?? []) {
      if (l?.begin) {
        l.begin = new Date(l?.begin).toISOString()
      }
      if (l?.end) {
        l.end = new Date(l?.end).toISOString()
      }
      if (!l?.decode) delete l.decode
      if (l?.decode) {
        l.decode = parseDecodeOrder(l?.decode)
      }
    }
    for (const c of values?.use?.cable ?? []) {
      if (c?.begin) {
        c.begin = new Date(c?.begin).toISOString()
      }
      if (c?.end) {
        c.end = new Date(c?.end).toISOString()
      }
      if (!c?.decode) delete c.decode
      if (c?.decode) {
        c.decode = parseDecodeOrder(c?.decode)
      }
    }

    if (!(values.ts.created instanceof Date)) delete values.ts.created
    const { error, value } = siteSchema.validate(values, {
      abortEarly  : false,
      stripUnknown: true,
    })

    logValidationErrors(error)

    return { error, value }
  },

  mutationOptions: {
    // onMutate: (variables) => console.log('onMutate', variables),
    // onError: (error, variables, context) => {
    //   // console.log('onError', error, variables, context);
    //   // return error;
    // },
    onSuccess: (data: any, variables: any, context) => {
      if (data?.error) throw data.error
      if (data?.updated === false)
        throw new Error('encountered error updating entry')

      // console.log('onSuccess', data, variables, context);
      queryClient.invalidateQueries([
        'sites',
        { 'project.id': variables?.project?.id },
      ])
      queryClient.invalidateQueries(['reading', { 'site.id': variables?.id }])
      queryClient.invalidateQueries(['site', { id: `${variables?.id}` }])
      queryClient.invalidateQueries('sites')
      return data
    },
  },

  closeCondition: (res) => res?.updated === true,
})
