import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

/** Identifier type for public.cloudloop_cache_ */
export type CloudloopCacheId = string & { __brand: 'CloudloopCacheId' }

/** Represents the table public.cloudloop_cache_ */
export default interface CloudloopCacheTable {
  /** Database type: pg_catalog.uuid */
  id: ColumnType<
    CloudloopCacheId,
    CloudloopCacheId | undefined,
    CloudloopCacheId
  >

  /** Database type: pg_catalog.text */
  imei: ColumnType<string, string, string>

  /** Database type: pg_catalog.timestamptz */
  updatedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.jsonb */
  cacheData: ColumnType<unknown, unknown, unknown>

  /** Database type: pg_catalog.text */
  hardwareId: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  subscriberId: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.text */
  networkStatus: ColumnType<string | null, string | null, string | null>
}

export type CloudloopCache = Selectable<CloudloopCacheTable>

export type CloudloopCacheInitializer = Insertable<CloudloopCacheTable>

export type CloudloopCacheMutator = Updateable<CloudloopCacheTable>

export const cloudloopCacheId =
  z.string() as unknown as z.Schema<CloudloopCacheId>

export const cloudloopCache = z.object({
  id           : cloudloopCacheId,
  imei         : z.string(),
  updatedAt    : z.coerce.date(),
  cacheData    : z.unknown(),
  hardwareId   : z.string().nullable().optional(),
  subscriberId : z.string().nullable().optional(),
  networkStatus: z.string().nullable().optional(),
}) as unknown as z.Schema<CloudloopCache>

export const cloudloopCacheInitializer = z.object({
  id           : cloudloopCacheId.optional(),
  imei         : z.string(),
  updatedAt    : z.coerce.date(),
  cacheData    : z.unknown(),
  hardwareId   : z.string().optional().nullable().optional(),
  subscriberId : z.string().optional().nullable().optional(),
  networkStatus: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<CloudloopCacheInitializer>

export const cloudloopCacheMutator = z.object({
  id           : cloudloopCacheId.optional(),
  imei         : z.string().optional(),
  updatedAt    : z.coerce.date().optional(),
  cacheData    : z.unknown().optional(),
  hardwareId   : z.string().optional().nullable().optional(),
  subscriberId : z.string().optional().nullable().optional(),
  networkStatus: z.string().optional().nullable().optional(),
}) as unknown as z.Schema<CloudloopCacheMutator>
