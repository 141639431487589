import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import {
  ModemHistoryId,
  ModemHistoryMutator,
  ModemId,
  ModemWithContextInitializer,
  ModemWithContextMutator,
} from '@beaded/models'

import type { Obj, IMEI } from 'types/common'

import * as apiUtils from '.'

const url = '/modem'

export const get = (imei: IMEI | ModemId, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${imei}`, merge({}, apiUtils.extrasFlags, options))

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: ModemWithContextInitializer) =>
  apiUtils.POST(`${url}/`, data)

export const createHistory = (
  imei: IMEI | ModemId,
  data: ModemHistoryMutator,
) => apiUtils.PUT(`${url}/${imei}/history`, data)

export const removeHistory = (historyId: ModemHistoryId) =>
  apiUtils.DELETE(`${url}/history/${historyId}`)

export const update = (imei: IMEI | ModemId, data: ModemWithContextMutator) =>
  apiUtils.PUT(`${url}/${imei}`, data)

export const patch = (imei: IMEI | ModemId, data: Obj) =>
  apiUtils.PATCH(`${url}/${imei}`, data)

export const remove = (imei: IMEI) => apiUtils.DELETE(`${url}/${imei}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const getModemHistory = (
  historyId: ModemHistoryId,
  options: AxiosRequestConfig = {},
) => apiUtils.GET(`${url}/history/${historyId}`, options)

export const validateImei = (imei: string) =>
  apiUtils.GET(`${url}/validate`, { params: { imei } })

export const admin = {
  get: (imei: IMEI, options: AxiosRequestConfig = {}) =>
    get(imei, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
}
