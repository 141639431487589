import { AnyZodObject, z } from 'zod'

import { OrganizationId } from '../schemas/public/Organization.js'
import {
  Project,
  ProjectInitializer,
  projectInitializer,
  ProjectMutator,
} from '../schemas/public/Project.js'
import { EmbeddedLoggerLastTransmission } from './LoggerWithContext.js'

export type ProjectWithOrgInitializer = ProjectInitializer & {
  organizationId: OrganizationId
}

export type ProjectWithOrgMutator = ProjectMutator & {
  organizationId: OrganizationId
}

export type ProjectExtras = {
  activeSiteCount?: number
  cableCount?: number
  lastLogged?: string
  loggerCount?: number
  retiredSiteCount?: number
  siteCount?: number
  geo?: Array<{
    id: number
    name: string
    status: string
    visible: boolean
    latitude: number
    longitude: number
  }>
  changelog?: any
}

export type ProjectWithOrganization = Project & {
  organizationId: OrganizationId
  organizationName: string
  extras?: ProjectExtras
  lastTransmission?: EmbeddedLoggerLastTransmission
}

export const orgFields = z.object({
  organizationId: z.number().int().positive(),
})

export const projectSlug = z.object({
  slug: z
    .preprocess(
      (v, ctx) =>
        typeof v === 'string' && v.trim().toLowerCase().replace(/_/g, '-'),
      z.string().regex(/^[a-z0-9]([-_]?[a-z0-9])*$/),
    )
    .nullable()
    .optional(),
})

export const projectWithOrgInitializer = orgFields
  .merge(projectInitializer as unknown as AnyZodObject)
  .merge(
    projectSlug as unknown as AnyZodObject,
  ) as unknown as z.Schema<ProjectWithOrgInitializer>

export const projectWithOrgMutator = orgFields
  .merge(projectInitializer as unknown as AnyZodObject)
  .merge(
    projectSlug as unknown as AnyZodObject,
  ) as unknown as z.Schema<ProjectWithOrgMutator>
