import {
  FormControl,
  FormErrorMessage,
  FormLabel,
  Text,
} from '@chakra-ui/react'
import { HStack } from '@chakra-ui/react'
import { Select } from 'chakra-react-select'
import { memoize } from 'lodash'
import { useState } from 'react'
import { useFormContext } from 'react-hook-form'

import { InfoTooltip } from 'components/InfoTooltip'

import { BaseInputProps } from 'forms/helpers/types'

export type MultiSelectInputProps = BaseInputProps & {
  options: string[] | Array<{ name: string; value: string | number }>
  selections?: string[]
  maxSelections?: number
  resetOnFieldChange?: string
}

export const MultiSelectInput = (props: MultiSelectInputProps) => {
  const {
    setValue,
    register,
    watch,
    formState: { errors },
  } = useFormContext()
  const [currentSelection, setCurrentSelection] = useState<any[]>(
    props.selections ?? [],
  )

  watch((data, { name, type }) => {
    if (props.resetOnFieldChange === name) {
      setCurrentSelection([])
      setValue(props.name, [])
    }
  })

  const onSelect = (res: any[]) => {
    if (props.maxSelections && res.length <= props.maxSelections) {
      setCurrentSelection(res)
      setValue(
        props.name,
        res.map((r) => r.value),
      )
    }
    else {
      setCurrentSelection(res)
      setValue(
        props.name,
        res.map((r) => r.value),
      )
    }
  }

  return (
    <>
      <FormControl
        isRequired={Boolean(props?.hookformProps?.required)}
        isInvalid={Boolean(errors[props.name])}
      >
        <FormLabel htmlFor={props.name}>{props.label ?? props.name}</FormLabel>
        {props.description && <Text>{props.description}</Text>}
        <HStack>
          <Select
            {...register(props.name, props.hookformProps)}
            isMulti={true}
            placeholder={props.placeholder}
            options={
              Array.isArray(props.options)
                ? props.options.map(convertOptionsForMultiSelect)
                : []
            }
            value={currentSelection}
            onChange={onSelect}
            closeMenuOnSelect={false}
            chakraStyles={{
              dropdownIndicator: (provided: any) => ({
                ...provided,
                bg    : 'transparent',
                px    : 2,
                cursor: 'inherit',
              }),
              indicatorSeparator: (provided: any) => ({
                ...provided,
                display: 'none',
              }),
            }}
            styles={{
              container: (provided: any) => ({
                ...provided,
                width: '100%',
              }),
            }}
          />

          {props.tooltip && <InfoTooltip text={props.tooltip} />}
        </HStack>
        <FormErrorMessage>{errors[props.name]?.message}</FormErrorMessage>
      </FormControl>
    </>
  )
}

const convertOptionsForMultiSelect = memoize(({ value, name }) => ({
  value,
  name,
  label: name,
}))
