import type { QueryKey, QueryFunction } from 'react-query'

import { UserWithPermissions } from '@beaded/models'

import { project } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

export const useProjectsList = (orgId: number, user: UserWithPermissions) => {
  const { superUser } = useUserContext()

  const options: [QueryKey, QueryFunction] =
    superUser === 'on'
      ? [
        ['projects', { 'org.id': orgId, projection: 'menu' }, { superUser }],
        ({ queryKey }) => project.admin.getAll({ params: queryKey[1] }),
      ]
      : ['projects', ({ queryKey }) => project.getAll({ params: queryKey[1] })]

  const { data, ...rest } = useQuery<{ proj: any }>(...options)

  // console.log(perms)

  const permsMap = Object.values(data?.proj ?? []).reduce(
    (acc: Array<any>, val: any) => {
      acc.push({
        project: val,
        perm   : user.permissions?.projects?.find((p: any) => p?.id === val.id),
      })
      return acc
    },
    [],
  )

  return {
    permsMapping: permsMap,
    ...rest,
  }
}
