export const camelCaseToEnglish = (camelCase: string) => {
  // camelCase is all lowercase with the first letter of each word capitalized

  return camelCase
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase())
}

export const convertMacroCaseToEnglish = (macroCase: string) => {
  // MACRO_CASE is all caps with underscores between words

  return macroCase
    .split('_')
    .map((word) => word.toLowerCase())
    .join(' ')
    .replace(/^./, (str) => str.toUpperCase())
}
