import type { QueryKey, QueryFunction, UseQueryOptions } from 'react-query'

import { Organization } from '@beaded/models'

import { org } from 'api'

import { useQuery } from 'hooks/useQuery'
import { useUserContext } from 'hooks/useUserContext'

import type { Obj } from 'types/common'

export const useOrgsList = (filter?: Obj, queryOpts: UseQueryOptions = {}) => {
  const { superUser } = useUserContext()

  const options: [QueryKey, QueryFunction, UseQueryOptions] =
    superUser === 'on'
      ? [['orgs', filter, { superUser }], () => org.admin.getAll(), queryOpts]
      : [['orgs', filter], () => org.getAll(), queryOpts]

  const { data, ...rest } = useQuery<{ org: Organization | Organization[] }>(
    ...options,
  )

  return {
    orgs: Array.isArray(data?.org) ? data?.org : [data?.org],
    ...rest,
  }
}
