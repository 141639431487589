import type { AxiosRequestConfig } from 'axios'
import merge from 'lodash.merge'

import {
  LoggerEventInitializer,
  LoggerHistoryId,
  LoggerHistoryInitializer,
  LoggerHistoryMutator,
  LoggerId,
  LoggerWithContextInitializer,
  LoggerWithContextMutator,
} from '@beaded/models'

import type { Obj } from 'types/common'

import * as apiUtils from '.'

const url = '/logger'

export const get = (id: LoggerId | string, options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/${id}`, merge({}, apiUtils.extrasFlags, options))

export const getAll = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({}, apiUtils.extrasFlags, options))

export const create = (data: LoggerWithContextInitializer) =>
  apiUtils.POST(`${url}/`, data)

export const createEvent = (loggerId: LoggerId, data: LoggerEventInitializer) =>
  apiUtils.PUT(`${url}/${loggerId}/event`, data)

export const createHistory = (
  loggerId: LoggerId,
  data: LoggerHistoryInitializer,
) => apiUtils.POST(`${url}/${loggerId}/history`, data)

export const bulkUpdateHistory = (
  loggerId: LoggerId,
  data: LoggerHistoryMutator[],
) => apiUtils.PUT(`${url}/${loggerId}/history`, data)

export const updateHistory = (
  loggerId: LoggerId,
  loggerHistoryId: LoggerHistoryId,
  data: LoggerHistoryMutator,
) => apiUtils.PUT(`${url}/${loggerId}/history/${loggerHistoryId}`, data)

export const removeHistory = (
  loggerId: LoggerId,
  loggerHistoryId: LoggerHistoryId,
) => apiUtils.DELETE(`${url}/${loggerId}/history/${loggerHistoryId}`)

export const update = (id: LoggerId, data: LoggerWithContextMutator) =>
  apiUtils.PUT(`${url}/${id}`, data)

export const patch = (id: LoggerId, data: Obj) =>
  apiUtils.PATCH(`${url}/${id}`, data)

export const remove = (id: LoggerId) => apiUtils.DELETE(`${url}/${id}`)

export const getMenuProjection = (options: AxiosRequestConfig = {}) =>
  apiUtils.GET(`${url}/`, merge({ params: { projection: 'menu' } }, options))

export const validateSerial = (serial: string) =>
  apiUtils.GET(`${url}/validate`, { params: { serial } })

export const admin = {
  get: (id: LoggerId | string, options: AxiosRequestConfig = {}) =>
    get(id, merge({}, apiUtils.globalFlags, options)),
  getAll: (options: AxiosRequestConfig = {}) =>
    getAll(merge({}, apiUtils.globalFlags, options)),
  getMenuProjection: (options: AxiosRequestConfig = {}) =>
    getMenuProjection(merge({}, apiUtils.globalFlags, options)),
  sendSbdMessage: (
    id: LoggerId,
    message: string,
    options: AxiosRequestConfig = {},
  ) =>
    apiUtils.PUT(
      `${url}/${id}/sbd`,
      { id, message },
      merge({}, apiUtils.globalFlags, options),
    ),
}
