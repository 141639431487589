import { CheckCircleIcon, WarningIcon } from '@chakra-ui/icons'
import {
  Button,
  chakra,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Spacer,
  VStack,
} from '@chakra-ui/react'
import { zodResolver } from '@hookform/resolvers/zod'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

import {
  LoggerConditionType,
  loggerHistoryInitializer,
  LoggerHistoryInitializer,
  LoggerId,
  OrganizationId,
  ProjectId,
} from '@beaded/models'

import { logger } from 'api'

import { ModalContainer } from 'containers/ModalContainer'

import { MaskedInput } from './inputs/MaskedInput'
import { SelectInput } from './inputs/SelectInput'
import { SelectLogger } from './inputs/SelectLogger'
import { SelectOrganization } from './inputs/SelectOrganization'
import { SelectProjectWithOrgFilter } from './inputs/SelectProject'

type AddLoggerHistoryProps = {
  buttonText?: string
  initialValues?: LoggerHistoryInitializer
  organizationId?: OrganizationId
  projectId?: ProjectId
  buttonSize?: string
  loggerId?: LoggerId
}

const loggerConditionChoices = Object.values(LoggerConditionType)

export const AddLoggerHistory = (props: AddLoggerHistoryProps) => {
  const methods = useForm<LoggerHistoryInitializer>({
    defaultValues: {
      loggerId: props.loggerId
        ? (Number(props.loggerId) as LoggerId)
        : undefined,
      organizationId: props.organizationId
        ? (Number(props.organizationId) as OrganizationId)
        : undefined,
      projectId: props.projectId
        ? (Number(props.projectId) as ProjectId)
        : undefined,
      startedAt: new Date().toISOString(),
    },
    resolver: zodResolver(loggerHistoryInitializer),
  })

  const mutation = useMutation(async (data: LoggerHistoryInitializer) => {
    const validationResult = loggerHistoryInitializer.safeParse(data)

    if (validationResult.success === false) {
      console.error(validationResult.error)
      return { error: validationResult.error }
    }

    const result = await logger.createHistory(data.loggerId, data)
    return result
  })

  const handler = (data: LoggerHistoryInitializer) => {
    return mutation.mutateAsync(data)
  }

  return (
    <ModalContainer
      TriggerButton={({ onClick }: any) => (
        <Button size={props.buttonSize ?? 'md'} onClick={onClick}>
          {props.buttonText ?? 'Add Logger History'}
        </Button>
      )}
      modalProps={{ size: '2xl' }}
      header='Add Logger History'
      footer={() => (
        <VStack justifyContent='right' align='right' width='100%'>
          <HStack>
            <Spacer />
            {mutation.isError && (
              <pre>
                <chakra.code color='red.400'>
                  {JSON.stringify(mutation.error, null, 2)}
                </chakra.code>
              </pre>
            )}
            {mutation.isSuccess && <CheckCircleIcon color='green.400' />}
            {mutation.isError && <WarningIcon color='red.400' />}
            <Button
              onClick={methods.handleSubmit(handler)}
              isLoading={mutation.isLoading}
            >
              Submit
            </Button>
          </HStack>
        </VStack>
      )}
      onClose={methods.reset}
    >
      <FormProvider {...methods}>
        <form>
          <Grid templateColumns='repeat(6, 1fr)' gap={4}>
            {props?.loggerId !== undefined ? null : (
              <>
                <GridItem colSpan={3}>
                  <SelectLogger
                    label='Logger'
                    name='loggerId'
                    hookformProps={{
                      required     : true,
                      valueAsNumber: true,
                    }}
                  />
                </GridItem>
                <GridItem colSpan={3} />
              </>
            )}

            <GridItem colSpan={3}>
              <SelectOrganization
                label='Organization'
                name='organizationId'
                hookformProps={{
                  required     : true,
                  valueAsNumber: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={3}>
              <SelectProjectWithOrgFilter
                name='projectId'
                label='Project'
                watchField='organizationId'
                hookformProps={{
                  required     : true,
                  valueAsNumber: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <SelectInput
                name='condition'
                label='Condition'
                options={loggerConditionChoices}
                hookformProps={{
                  required: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <MaskedInput
                name='startedAt'
                label='Start Date'
                format='####-##-##'
                placeholder='YYYY-MM-DD'
                hookformProps={{
                  required: true,
                }}
              />
            </GridItem>

            <GridItem colSpan={2}>
              <MaskedInput
                name='endedAt'
                label='End Date'
                format='####-##-##'
                placeholder='YYYY-MM-DD'
                hookformProps={{}}
              />
            </GridItem>
          </Grid>
        </form>
      </FormProvider>
    </ModalContainer>
  )
}
