import {
  type ColumnType,
  type Selectable,
  type Insertable,
  type Updateable,
} from 'kysely'
import { z } from 'zod'

import { siteId, type SiteId } from './Site.js'

/** Identifier type for public.site_logger_history_ */
export type SiteLoggerHistoryId = number & { __brand: 'SiteLoggerHistoryId' }

/** Represents the table public.site_logger_history_ */
export default interface SiteLoggerHistoryTable {
  /** Database type: pg_catalog.int4 */
  id: ColumnType<
    SiteLoggerHistoryId,
    SiteLoggerHistoryId | undefined,
    SiteLoggerHistoryId
  >

  /** Database type: pg_catalog.int4 */
  siteId: ColumnType<SiteId, SiteId, SiteId>

  /** Database type: pg_catalog.text */
  loggerSerial: ColumnType<string | null, string | null, string | null>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date, Date | string, Date | string>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, Date | string | null, Date | string | null>

  /** Database type: pg_catalog.int4[] */
  decode: ColumnType<number[] | null, number[] | null, number[] | null>
}

export type SiteLoggerHistory = Selectable<SiteLoggerHistoryTable>

export type SiteLoggerHistoryInitializer = Insertable<SiteLoggerHistoryTable>

export type SiteLoggerHistoryMutator = Updateable<SiteLoggerHistoryTable>

export const siteLoggerHistoryId =
  z.number() as unknown as z.Schema<SiteLoggerHistoryId>

export const siteLoggerHistory = z.object({
  id          : siteLoggerHistoryId,
  siteId      : siteId,
  loggerSerial: z.string().nullable().optional(),
  startedAt   : z.coerce.date(),
  endedAt     : z.coerce.date().nullable().optional(),
  decode      : z.number().array().nullable().optional(),
}) as unknown as z.Schema<SiteLoggerHistory>

export const siteLoggerHistoryInitializer = z.object({
  id          : siteLoggerHistoryId.optional(),
  siteId      : siteId,
  loggerSerial: z.string().optional().nullable().optional(),
  startedAt   : z.coerce.date(),
  endedAt     : z.coerce.date().optional().nullable().optional(),
  decode      : z.number().array().optional().nullable().optional(),
}) as unknown as z.Schema<SiteLoggerHistoryInitializer>

export const siteLoggerHistoryMutator = z.object({
  id          : siteLoggerHistoryId.optional(),
  siteId      : siteId.optional(),
  loggerSerial: z.string().optional().nullable().optional(),
  startedAt   : z.coerce.date().optional(),
  endedAt     : z.coerce.date().optional().nullable().optional(),
  decode      : z.number().array().optional().nullable().optional(),
}) as unknown as z.Schema<SiteLoggerHistoryMutator>
