import { HStack, Spacer, Text, Skeleton } from '@chakra-ui/react'

import { ErrorCard } from 'components/ErrorCard'
import {
  LoggersTable,
  LoggersTableProjection,
} from 'components/tables/LoggersTable.component'

import { Card } from 'containers/Card'

import { AddLoggerHistory } from 'forms/AddLoggerHistory'
import { CreateLogger } from 'forms/CreateLogger'

import { useTableDownload } from 'hooks/useTableDownload'

import { getTableTitle } from 'lib/getTableTitle'

import type { PageCard } from 'types/PageCard'

import { useLoggersList } from './useLoggersList'

export type LoggersCardProps = PageCard<LoggersTableProjection> & {
  showAddLogger?: boolean
  orgId?: String
}

export const LoggersCard = ({
  type,
  input,
  globalFilter,
  global,
  initialValues = {},
  projection,
  filename = 'loggers.csv',
  remapData,
  filterData,
  showAddLogger = false,
  sortBy = [],
  orgId,
}: LoggersCardProps) => {
  const { isLoading, errors, loggers } = useLoggersList(
    type === 'query' ? input : null,
    {
      enabled: type === 'query',
    },
    remapData,
    filterData,
  )

  const { download, DownloadButton } = useTableDownload(filename)

  const name = getTableTitle('Logger', type === 'query' ? loggers : input)

  return (
    <>
      <Card>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>{name}</Text>
          <Spacer />
          {global.view && <DownloadButton />}
          {global.edit && showAddLogger && (
            <AddLoggerHistory
              buttonText='Add Logger'
              organizationId={initialValues?.org?.id}
              projectId={initialValues?.project?.id}
            />
          )}
          {global.edit && window.location.pathname === '/loggers' && (
            <CreateLogger />
          )}
        </HStack>

        <Skeleton isLoaded={!isLoading}>
          {errors ? (
            <ErrorCard errors={errors} />
          ) : (
            <LoggersTable
              data={type === 'query' ? loggers : input}
              globalFilter={globalFilter}
              download={download}
              projection={projection}
              sortBy={sortBy}
            />
          )}
        </Skeleton>
      </Card>
    </>
  )
}
