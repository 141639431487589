import { DownloadIcon } from '@chakra-ui/icons'
import { IconButton, Text, HStack, Spacer } from '@chakra-ui/react'

import { ErrorCard } from 'components/ErrorCard'
import { ErrorWrapper } from 'components/ErrorWrapper'
import { Loading } from 'components/Loading'
import { UsersTable } from 'components/tables/UsersTable.component'

import { Card } from 'containers/Card'

import { CreateUserModal } from 'forms/CreateUser'

import { useDocumentTitle } from 'hooks/useDocumentTitle'
import { useSearchParams } from 'hooks/useSearchParams'
import { useUserContext } from 'hooks/useUserContext'

// import { createUserForm } from 'lib/mutations/create/createUser'
import { useUsersList } from './useUsersList'

export default function UsersWrapper() {
  useDocumentTitle('Users')
  const { globalFilter } = useSearchParams()
  const { global, superUser } = useUserContext()
  const { isLoading, errors, users } = useUsersList(superUser)

  if (isLoading) return <Loading />

  if (errors) return <ErrorCard errors={errors} />

  return (
    <ErrorWrapper>
      <Users data={users} globalFilter={globalFilter} global={global} />
    </ErrorWrapper>
  )
}

export const Users = ({ data = [], globalFilter, global }) => {
  const download = {
    filename: 'users.csv',
    fn      : undefined,
  }

  return (
    <>
      <Card style={{ maxWidth: 'max-content' }}>
        <HStack pb='1rem'>
          <Text layerStyle='heading'>{`${data.length} `}Users</Text>
          <Spacer />
          {global.view && (
            <IconButton
              onClick={() =>
                typeof download?.fn === 'function' && download.fn()
              }
              aria-label='Download Table'
              icon={<DownloadIcon />}
              disabled={typeof download.fn !== 'undefined'}
            />
          )}
          {global.edit && <CreateUserModal />}
        </HStack>
        <UsersTable
          data={data}
          globalFilter={globalFilter}
          global={global}
          download={download}
        />
      </Card>
    </>
  )
}
