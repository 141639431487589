import { LoggerId } from '@beaded/models'

import { logger } from 'api'

import { queryClient } from 'lib/react-query'

export const deleteLogger = async (id: LoggerId) => {
  await logger.remove(id)
  queryClient.invalidateQueries('loggers')
}
