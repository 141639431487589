import type { QueryKey, QueryFunction, UseQueryResult } from 'react-query'
import { useQuery as useQueryRQ } from 'react-query'

export function useQuery<T = unknown>(
  queryKey: QueryKey,
  queryFn: QueryFunction,
  options = {},
): UseQueryResult<T> & { errors: string[] | null } {
  const res: any = useQueryRQ(queryKey, queryFn, options)

  const errors = new Set()

  res?.error && errors.add(res?.error?.toString())
  res?.error?.response?.data && errors.add(res?.error?.response?.data)

  res?.data?.error && errors.add(res.data.error)

  return {
    ...res,
    isSuccess: res.isSuccess && Boolean(res?.data),
    errors   : res.isError || errors.size > 0 ? [...errors] : null,
  }
}
