import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'

/** Represents the view public.logger_last_transmission_view_ */
export default interface LoggerLastTransmissionViewTable {
  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, never, never>

  /** Database type: pg_catalog.text */
  loggerSerial: ColumnType<string, never, never>

  /** Database type: pg_catalog.numeric */
  battery: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.jsonb */
  batteryTrend: ColumnType<unknown | null, never, never>

  /** Database type: pg_catalog.int4 */
  msgSize: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.int4 */
  errors85: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.int4 */
  errors99: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.int4 */
  errors125: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.int4 */
  numConnectedDevices: ColumnType<number | null, never, never>

  /** Database type: pg_catalog.timestamptz */
  recievedAt: ColumnType<Date, never, never>

  /** Database type: pg_catalog.text */
  imei: ColumnType<string | null, never, never>
}

export type LoggerLastTransmissionView =
  Selectable<LoggerLastTransmissionViewTable>

export const loggerLastTransmissionView = z.object({
  loggerId           : loggerId,
  loggerSerial       : z.string(),
  battery            : z.number().nullable().optional(),
  batteryTrend       : z.unknown().nullable().optional(),
  msgSize            : z.number().nullable().optional(),
  errors85           : z.number().nullable().optional(),
  errors99           : z.number().nullable().optional(),
  errors125          : z.number().nullable().optional(),
  numConnectedDevices: z.number().nullable().optional(),
  recievedAt         : z.coerce.date(),
  imei               : z.string().nullable().optional(),
}) as unknown as z.Schema<LoggerLastTransmissionView>
