import get from 'lodash.get'
import set from 'lodash.set'

const isObject = (obj: any) => obj != null && obj.constructor.name === 'Object'

export const getKeys = (
  obj: any,
  keepObjKeys: boolean = true,
  skipArrays: boolean = false,
  keys: string[] = [],
  scope: string[] = [],
) => {
  if (Array.isArray(obj)) {
    if (!skipArrays) scope.push(`[${obj.length}]`)
    obj.forEach((o) => getKeys(o, keepObjKeys, skipArrays, keys, scope), keys)
  }
  else if (isObject(obj)) {
    Object.keys(obj).forEach((k) => {
      if ((!Array.isArray(obj[k]) && !isObject(obj[k])) || keepObjKeys) {
        const path = scope.concat(k).join('.').replace(/\.\[/g, '[')
        if (!keys.includes(path)) keys.push(path)
      }
      getKeys(obj[k], keepObjKeys, skipArrays, keys, scope.concat(k))
    }, keys)
  }
  return keys
}

export type DownloadColumn =
  | { column: string; name: string }
  | { name: string; aggregation: (data: any) => string }
  | string

export const filterObject = (
  obj: any,
  permittedColumns: Array<DownloadColumn>,
) => {
  const result = {}

  for (const column of permittedColumns) {
    if (typeof column === 'string') {
      set(result, column, get(obj, column, null))
    }
    else if ('column' in column && 'name' in column) {
      set(result, column.name, get(obj, column.column, null))
    }
    else if ('aggregation' in column && 'name' in column) {
      set(result, column.name, column.aggregation(obj))
    }
  }

  return result
}
