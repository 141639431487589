import { type ColumnType, type Selectable } from 'kysely'
import { z } from 'zod'

import { loggerId, type LoggerId } from './Logger.js'
import { modemId, type ModemId } from './Modem.js'
import { modemHistoryId, type ModemHistoryId } from './ModemHistory.js'
import {
  modemPlatformType,
  type default as ModemPlatformType,
} from './ModemPlatformType.js'
import {
  modemProviderType,
  type default as ModemProviderType,
} from './ModemProviderType.js'

/** Represents the view public.logger_modems_view_ */
export default interface LoggerModemsViewTable {
  /** Database type: pg_catalog.int4 */
  loggerId: ColumnType<LoggerId, never, never>

  /** Database type: pg_catalog.int4 */
  id: ColumnType<ModemHistoryId, never, never>

  /** Database type: pg_catalog.text */
  imei: ColumnType<string, never, never>

  /** Database type: public.modem_platform_type_ */
  platform: ColumnType<ModemPlatformType, never, never>

  /** Database type: public.modem_provider_type_ */
  provider: ColumnType<ModemProviderType, never, never>

  /** Database type: pg_catalog.text */
  networkStatus: ColumnType<string | null, never, never>

  /** Database type: pg_catalog.int4 */
  modemId: ColumnType<ModemId, never, never>

  /** Database type: pg_catalog.timestamptz */
  startedAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.timestamptz */
  endedAt: ColumnType<Date | null, never, never>

  /** Database type: pg_catalog.text */
  notes: ColumnType<string | null, never, never>
}

export type LoggerModemsView = Selectable<LoggerModemsViewTable>

export const loggerModemsView = z.object({
  loggerId     : loggerId,
  id           : modemHistoryId,
  imei         : z.string(),
  platform     : modemPlatformType,
  provider     : modemProviderType,
  networkStatus: z.string().nullable().optional(),
  modemId      : modemId,
  startedAt    : z.coerce.date().nullable().optional(),
  endedAt      : z.coerce.date().nullable().optional(),
  notes        : z.string().nullable().optional(),
}) as unknown as z.Schema<LoggerModemsView>
